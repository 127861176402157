/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --Roboto: "Roboto", sans-serif;
} */



/* new css by aakhil */
.my-account {
  padding-bottom: 100px;
}

.container-ws {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row-ws {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-ws {
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;
  flex: 0 0 100%;
}

/* Large screens */
@media (min-width: 992px) {
  .col8 {
      flex: 0 0 70.00%;
      max-width: 70.00%;
  }

  .col4 {
      flex: 0 0 30.00%;
      max-width: 30.00%;
  }
}

.myaccount-right {

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; 
}

.col {
  box-sizing: border-box;
  padding: 0 15px; 
}


@media (min-width: 576px) {
  .col-12 { flex: 0 0 100%; max-width: 100%; }
  .col-6 { flex: 0 0 50%; max-width: 50%; }
  
}

@media (min-width: 768px) {
  .col-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  
}

@media (min-width: 992px) {
  .col-3 { flex: 0 0 25%; max-width: 25%; }
  
}

@media (min-width: 1200px) {
  .col-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  
}

}

.myaccount-right .container {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
  }
  
.myaccount-right .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0; 
    width: 100%;
  }
.myaccount-right .col {
    padding: 0 10px; 
    box-sizing: border-box;
    width: 100%;
    flex: 0 0 100%;
  }

  @media (min-width: 768px) {
    .myaccount-right .col1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
    .myaccount-right .col2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
    .myaccount-right .col3 { flex: 0 0 25%; max-width: 25%; }
    .myaccount-right .col4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
    .myaccount-right .col5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
    .myaccount-right .col6 { flex: 0 0 50%; max-width: 50%; }
    .myaccount-right .col7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
    .myaccount-right .col8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
    .myaccount-right .col9 { flex: 0 0 75%; max-width: 75%; }
    .myaccount-right .col10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
    .myaccount-right .col11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
    .myaccount-right .col12 { flex: 0 0 100%; max-width: 100%; }
}


/* rating */

.write-review-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 400px;
}

.write-review-div .write {
  color: rgba(243, 96, 30, 1);
  font-size: 12px;
  line-height: 16px;
  margin-left: 30px;
}

.write-review-div ul {
  display: flex;
  align-items: center;
}

.write-review-div ul li{
  display: inline;
}

.write-review-div ul li span {
  height: 24px;
  width: 24px;
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.27569 10.9208L10.4279 12.9179C10.8308 13.1732 11.3311 12.7935 11.2115 12.3232L10.3008 8.74052C10.2752 8.64073 10.2782 8.53573 10.3096 8.4376C10.3409 8.33946 10.3994 8.25218 10.4781 8.18577L13.3049 5.83306C13.6763 5.52392 13.4846 4.90751 13.0074 4.87654L9.31588 4.63696C9.21645 4.62986 9.12109 4.59465 9.04089 4.53545C8.96069 4.47625 8.89896 4.39548 8.86289 4.30255L7.48612 0.835486C7.44869 0.736852 7.38215 0.651935 7.29532 0.592013C7.2085 0.532092 7.1055 0.5 7 0.5C6.89451 0.5 6.79151 0.532092 6.70468 0.592013C6.61786 0.651935 6.55131 0.736852 6.51389 0.835486L5.13712 4.30255C5.10104 4.39548 5.03932 4.47625 4.95912 4.53545C4.87892 4.59465 4.78355 4.62986 4.68412 4.63696L0.992627 4.87654C0.515435 4.90751 0.323731 5.52392 0.695149 5.83306L3.52186 8.18577C3.60063 8.25218 3.65907 8.33946 3.69044 8.4376C3.72181 8.53573 3.72485 8.64073 3.6992 8.74052L2.85459 12.063C2.71111 12.6274 3.31143 13.083 3.79495 12.7767L6.72431 10.9208C6.8067 10.8683 6.90234 10.8405 7 10.8405C7.09767 10.8405 7.19331 10.8683 7.27569 10.9208V10.9208Z' stroke='%23646464' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A"); 
  background-repeat: no-repeat; 
  background-size: 20px;
}

.write-review-div ul li span.fill {
  height: 24px;
  width: 24px;
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='%23F3601E' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.27569 10.9208L10.4279 12.9179C10.8308 13.1732 11.3311 12.7935 11.2115 12.3232L10.3008 8.74052C10.2752 8.64073 10.2782 8.53573 10.3096 8.4376C10.3409 8.33946 10.3994 8.25218 10.4781 8.18577L13.3049 5.83306C13.6763 5.52392 13.4846 4.90751 13.0074 4.87654L9.31588 4.63696C9.21645 4.62986 9.12109 4.59465 9.04089 4.53545C8.96069 4.47625 8.89896 4.39548 8.86289 4.30255L7.48612 0.835486C7.44869 0.736852 7.38215 0.651935 7.29532 0.592013C7.2085 0.532092 7.1055 0.5 7 0.5C6.89451 0.5 6.79151 0.532092 6.70468 0.592013C6.61786 0.651935 6.55131 0.736852 6.51389 0.835486L5.13712 4.30255C5.10104 4.39548 5.03932 4.47625 4.95912 4.53545C4.87892 4.59465 4.78355 4.62986 4.68412 4.63696L0.992627 4.87654C0.515435 4.90751 0.323731 5.52392 0.695149 5.83306L3.52186 8.18577C3.60063 8.25218 3.65907 8.33946 3.69044 8.4376C3.72181 8.53573 3.72485 8.64073 3.6992 8.74052L2.85459 12.063C2.71111 12.6274 3.31143 13.083 3.79495 12.7767L6.72431 10.9208C6.8067 10.8683 6.90234 10.8405 7 10.8405C7.09767 10.8405 7.19331 10.8683 7.27569 10.9208V10.9208Z' stroke='%23F3601E' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A"); 
  background-repeat: no-repeat;
  background-size: 19.5px;
}

.write-review-mobile {
  margin-bottom: 15px;
  margin-top: 5px;
}


/* alert */
.alert-box {
  border: 2px solid #e0ffe0;
  background-color: #e0ffe0;
  color: #000;
  padding: 15px;
  margin: 20px;
  border-radius: 5px;
  text-align: left;
  position: relative;
}

.alert-box p {
  margin: 0;
}

.alert-box-black {
  border: 2px solid rgba(0, 0, 0, 0.87);
  background-color:rgba(0, 0, 0, 0.87) ;
  color: #fff;
  padding: 15px;
  margin: 20px;
  border-radius: 5px;
  text-align: left;
  position: relative;
}

.alert-box-black p {
  margin: 0;
  display: flex;
  justify-content: space-between;
}


.titleHead {
  font-size: 26px;
  line-height: 26px;
  text-align: center;
  color: #444;
  margin-bottom: 30px;
}

.SupportDesk {
  padding: 30px 0;
  background: #f5f5f5;
}

.titleHead {
  font-size: 26px;
  line-height: 26px;
  text-align: center;
  color: #444;
  margin-bottom: 30px;
}

.tickets {
  padding: 30px 0;
  background: #f5f5f5;
}

.request-call {
  position: fixed;
  right: 0;
  top: 0;
  top: 230px;
  width: 65px;
  outline: none;
  z-index: 9;
} 


.buy-on-phone {
  position: fixed;
  right: 0;
  top: 32%;
  background: #fff;
  padding: 6px 9px;
  border-radius: 5px 0px 0 5px;
  box-shadow: 0 2px 4px rgb(8 8 8 / 22%);
  text-align: center;
  z-index: 1;
  line-height: 11px;
}

.buy-on-phone p {
  font-family: robotomedium, Arial, Helvetica, sans-serif;
  color: #333;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.02em;
  text-align: center;
}

.buy-on-phone p {
  font-size: 12px;
}

.deleteConfirm {
  height: 272px;
  min-width: 404px !important;
  border-radius: 0 !important;
}

.deleteConfirm > button {
  display: none;
}

.deleteConfirm button:nth-child(2) {
  border: 1px solid rgba(172, 172, 172, 1);
  color: rgba(51, 51, 51, 1) !important;
}

.deleteConfirm button {
  height: 40px;
  margin-top: 6px !important;
  border-radius: 5px !important;
}

@media (max-width: 768px) {

.SupportDesk {
  padding-top: 90px !important;
}

}