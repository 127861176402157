/* @import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */

:root {
  --font-pangramregular: pangramregular;
  --font-pangrammedium: pangrammedium;
  --font-Lato: Lato;
  --font-abhaya-libre: Abhaya Libre ExtraBold;
  --bg-orange-light: #e27a34;
  --bg-orange-light-hover: #e57200;

  --bg-orange-btn: #e27a34;
  --bg-orange-btn-hover: #e57200;
}

.breadcrumbs li:nth-last-child(-n + 1):after,
.hide {
  display: none;
}

.mr-0 {
  margin-right: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-auto {
  margin-left: auto !important;
}

.about-woodenstreet h2,
.about-woodenstreet h3,
.about-woodenstreet p a,
.common-text h2,
.common-text h3,
.common-text p a,
.menu ul li a:hover,
.menu ul li.menu-hover > a,
header .login,
header .login a,
header .top a:hover {
  color: #e57200;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
}

html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, Arial, "Helvetica Neue", sans-serif */
}

body,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
  /* font-family: "robotoregular"; */

  font-family: 'robotoregular', Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: normal;
}

blockquote {
  margin: 0;
}

article,
aside,
figcaption,
figure,
footer,
header,
main,
nav,
section {
  display: block;
}

b,
h1,
h2,
h3,
h4,
strong {
  padding: 0;
  font-weight: normal;
}

figure {
  margin: 0px;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
  max-width: 100%;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  /* font-family: sans-serif; */
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
  border: none;
  background: none;
}

button,
select {
  text-transform: none;
}

[type='reset'],
[type='submit'],
button,
html [type='button'] {
  -webkit-appearance: none;
}

[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type='checkbox'],
[type='radio'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
  padding: 0;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.clearfix:after,
.clearfix:before {
  content: ' ';
  display: block;
  height: 0;
  overflow: hidden;
}

.clearfix:after {
  clear: both;
}

.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  height: auto !important;
  overflow: inherit !important;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:after,
.slick-track:before {
  content: '';
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-next,
.slick-prev {
  font-size: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  left: -28px;
}

.slick-next:before,
.slick-prev:before {
  display: block;
  font-family: 'fontawesome';
  content: '\f053';
  font-size: 18px;
  color: #000;
}

.slick-next {
  right: -28px;
  left: inherit;
}

.slick-next:before {
  content: '\f054';
}

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 4px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:before {
  background: #000;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 11px;
  height: 11px;
  text-align: center;
  content: '';
}

.homeslide .slick-next,
.homeslide .slick-prev,
.interior-design .slick-next,
.interior-design .slick-prev {
  z-index: 1;
  left: 0;
  background: rgba(243, 243, 243, 0.8);
  opacity: 1;
  width: 43px;
  height: 79px;
  border-radius: 0 3px 3px 0;
  text-align: center;
}

.homeslide .slick-next:hover,
.homeslide .slick-prev:hover,
.interior-design .slick-next:hover,
.interior-design .slick-prev:hover {
  background: #f3f3f3;
}

.homeslide .slick-next,
.interior-design .slick-next {
  right: 0;
  left: inherit;
  border-radius: 3px 0px 0px 3px;
}

.customer-slider .slick-dots,
.slider-for .slick-dots {
  margin: auto;
  text-align: center;
}

.customer-slider .slick-dots li button:before,
.slider-for .slick-dots li button:before {
  border-radius: 100%;
  background: #fff;
  border: 1px solid #dbdbdb;
}

.customer-slider .slick-dots li.slick-active button:before,
.slider-for .slick-dots li.slick-active button:before {
  background: #f37920;
  border: 1px solid transparent;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
}

.container-fluid {
  width: 96%;
  max-width: 1900px;
  margin: 0 auto;
}

header {
  line-height: normal;
  z-index: 999;
  position: relative;
  background: #fff;
  padding-top: 30px;
}

.swiper-thumbs .swiper-slide-thumb-active {
  border: 1px solid #ee7120;
}

header .container {
  max-width: 1240px;
}

header .top {
  padding: 15px 0px 0px;
  text-align: right;
}

header .top li {
  display: inline-block;
  font-size: 0.8125rem;
  vertical-align: top;
  position: relative;
}

header .top li:nth-last-child(-n + 3) {
  border-left: 1px solid #e5e5e5;
}

header .top a {
  color: #5d5853;
  padding: 0 8px;
}

header .top i {
  margin-right: 7px;
  margin-top: -5px;
  display: inline-block;
  vertical-align: middle;
}

header .support-dropdown {
  display: none;
  position: absolute;
  left: -50px;
  top: 100%;
  text-align: left;
  width: 225px;
  z-index: 99;
  overflow: inherit !important;
}

header .support-option {
  position: relative;
  background: #fff;
  padding: 5px 0;
  border: 1px solid #e5e5e5;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.28);
  margin-top: 10px;
}

header .support-option li {
  display: block;
  border-bottom: 1px solid #e0e0e0;
  border-left: none !important;
}

header .support-option li:last-child {
  border-bottom: none;
}

header .support-option a {
  font-size: 0.8125rem;
  color: #5d5853;
  padding: 10px 12px;
  display: block;
  margin: 0;
  text-transform: capitalize;
}

header .support-option:after,
header .support-option:before {
  position: absolute;
  right: 0px;
  left: 0;
  margin: 0 auto;
  z-index: 9;
  top: -8px;
  content: '';
  width: 0;
  height: 0;
  clear: both;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

header .support-option:after {
  content: '';
  width: 0;
  height: 0;
  clear: both;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #efefef;
  top: -11px;
  z-index: 8;
}

header .bottom {
  padding: 0px 0px 5px;
}

header .logo {
  float: left;
  margin: -15px 0 10px;
}

header .header-search {
  width: 500px;
  position: relative;
  float: right;
}

header .form-control {
  width: 100%;
  height: 40px;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #efefef;
  border-radius: 3px;
  padding: 0px 40px 0px 15px;
  font-size: 0.875rem;
  color: #202020;
}

header .form-control.placeholder {
  color: #7d7d7d;
}

header .form-control:-moz-placeholder {
  color: #7d7d7d;
}

header .form-control::-moz-placeholder {
  color: #7d7d7d;
}

header .form-control:-ms-input-placeholder {
  color: #7d7d7d;
}

header .form-control::-webkit-input-placeholder {
  color: #7d7d7d;
}

header .btn-search {
  position: absolute;
  padding: 0px;
  border: none !important;
  right: 14px;
  outline: none;
  top: 10px;
  cursor: pointer;
  z-index: 1;
}

header .cart {
  font-size: 0.75rem;
  float: right;
  color: #4a4a4a;
  line-height: 17px;
  margin-left: 10px;
  position: relative;
  cursor: pointer;
}

header .cart i {
  display: block;
  text-align: center;
}

header .cart #cart {
  display: none;
}

header .header-right {
  display: none;
}

header .top-login {
  float: right;
  margin: 0px 10px;
}

header .top-login .my_account {
  position: relative;
  margin-top: 15px;
}

header .top-login .my_account:before {
  position: absolute;
  right: 2px;
  top: -2px;
  content: '\f107';
  background: #fff;
  font-family: 'fontawesome';
  font-size: 1.25rem;
}

header .top-login li {
  color: #000;
  text-align: center;
  font-size: 0.75rem;
}

header .top-login a {
  font-size: 0.75rem;
  color: #4a4a4a;
  margin-right: 0;
}

header .top-login i {
  display: block;
}

header .store,
header .whislist-cart {
  float: right;
  line-height: 17px;
  margin-left: 10px;
  margin-right: 10px;
  color: #4a4a4a;
  position: relative;
  font-size: 0.75rem;
}

header .store i,
header .whislist-cart i {
  display: block;
  text-align: center;
}

header .store {
  padding-left: 20px;
}

header .ready-ship img {
  width: 20px;
  height: 22px;
}

header .cart i,
header .store i,
header .top-login i,
header .whislist-cart i {
  height: 26px;
}

header .store {
  margin: 0px 15px;
}

header .store i {
  height: 25px;
}

.mobile-search {
  display: none;
}

.my_account,
.without-login {
  cursor: pointer;
  position: relative;
  padding-bottom: 20px;
  line-height: normal;
}

.my_account .sub,
.without-login .sub {
  background: #fff;
  position: absolute;
  z-index: 99999;
  width: 230px;
  -webkit-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  display: none;
  left: -90px;
  top: 40px;
}

.my_account .sub.dropbox,
.without-login .sub.dropbox {
  top: 55px;
}

.my_account .sub.dropbox .signIn-box,
.without-login .sub.dropbox .signIn-box {
  padding: 20px 0 0;
}

.my_account .sub.dropbox .signIn-box:hover .login-btn,
.without-login .sub.dropbox .signIn-box:hover .login-btn {
  background: #e57200;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.my_account .sub-options li + li,
.without-login .sub-options li + li {
  border-top: 1px solid #e7e7e7;
}

.my_account .sub-options li .login-btn,
.without-login .sub-options li .login-btn {
  background: #333333;
  color: #fff;
  display: inline-block;
  border-radius: 2px;
  text-transform: uppercase;
  padding: 7px 40px;
  font-family: 'robotomedium';
  font-size: 15px;
  line-height: 20px;
  margin: 0;
}

.my_account .sub-options li .login-btn:hover,
.without-login .sub-options li .login-btn:hover {
  color: #fff;
}

.my_account .sub-options li p,
.without-login .sub-options li p {
  text-transform: capitalize;
  color: #515151;
  margin: 15px auto;
  display: block;
  font-size: 13px;
  line-height: 16px;
}

.my_account .sub-options li p a,
.without-login .sub-options li p a {
  display: inline-block;
  color: #e27a34;
  margin: 0;
  padding: 5px 10px;
}

.my_account .sub-options li p a:hover,
.without-login .sub-options li p a:hover {
  color: #e27a34;
}

.my_account .sub-options li a,
.without-login .sub-options li a {
  padding: 12px 15px 12px 30px;
  display: block;
  text-transform: capitalize;
  text-align: left;
  color: #515151;
  font-size: 13px;
  line-height: 18px;
  position: relative;
}

.my_account .sub-options li a .header-icon,
.without-login .sub-options li a .header-icon {
  position: absolute;
  left: 15px;
  top: 10px;
}

.my_account .sub-options li a .header-icon svg,
.without-login .sub-options li a .header-icon svg {
  width: 22px;
  height: 22px;
}

.my_account .sub-options li a:hover,
.without-login .sub-options li a:hover {
  background: #f5f5f5;
}

.my_account .orange,
.without-login .orange {
  display: inline-block;
  vertical-align: top;
  width: 72px;
  height: 18px;
  padding-right: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: capitalize;
}

.fixed-header {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 999;
  -webkit-animation: animatetop 0.3s;
  animation: animatetop 0.3s;
}

.fixed-header + .space51 {
  padding-top: 51px;
}

.clientsliderWrapper {
  transition-timing-function: linear !important;
}

@-webkit-keyframes animatetop {
  0% {
    top: -90px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  0% {
    top: -90px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

.app-install-strip {
  display: none;
}

@media (max-width: 1024px) {
  .footer-mid .delivery-wrpr .accept-cards,
  .footer-mid .delivery-wrpr .download-wrpr {
    width: 28%;
  }

  .footer-mid .bottom .other-links {
    margin: 0 0 0 15px;
  }

  .footer-mid .bottom .footer-left a img {
    max-width: 150px;
  }

  .footer-common .detailtable table {
    width: 400px !important;
  }
}

@media screen and (max-width: 768px) {
  .strip-list .list-box {
    padding: 20px 0;
  }

  .strip-list .list-box .single-box {
    display: block;
    width: 19%;
    text-align: center;
  }

  .strip-list .list-box .single-box .box-text b {
    display: block;
    font-size: 17px;
    font-family: 'pangrammedium';
  }

  .strip-list .list-box .single-box .box-text br {
    display: none;
  }

  .strip-list .list-box .single-box .strip-logo {
    margin-right: 0;
    display: block;
  }

  .strip-list .list-box .single-box .strip-logo svg {
    height: 35px;
  }
}

.menu-hover .main_menu {
  display: block !important;
}

.menu-overlay.overlay {
  background: rgba(0, 0, 0, 0.7);
  z-index: 991;
  position: fixed;
  left: 0px;
  right: 0;
  top: 0;
  bottom: 0;
}

.main_menu {
  display: none;
}

nav ul li a {
  text-transform: capitalize !important;
}

.menu .menu-show .salemenus span {
  position: absolute;
  background: #ff4343;
  color: #fff;
  font-size: 10px;
  padding: 1px 4px;
  border-radius: 3px;
  left: 32px;
  top: 7px;
}

.menu {
  padding-top: 8px;
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
}

.menu .flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.menu ul {
  list-style: none;
  padding: 0;
  position: relative;
  text-transform: capitalize;
}

.menu ul li {
  float: left;
  padding: 0;
  margin: 0;
  font-size: 0.875rem;
}

.menu ul li a {
  text-decoration: none;
  padding: 5px 0px 15px 0px;
  margin: 0px 10px;
  display: inline-block;
  color: #535353;
  position: relative;
  text-decoration: none !important;
}

.menu ul li a:hover:before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  height: 2px;
  width: 100%;
  background: #e57200;
}

.menu ul li.decor {
  position: relative;
  background: #fff;
}

.menu ul li.decor .main_menu {
  width: 150px;
  min-height: initial !important;
  padding-bottom: 0;
}

.menu ul li.decor .main_menu article {
  margin-top: 15px;
  width: auto;
}

.menu ul li.decor .decor {
  padding: 0;
  margin: 0;
}

.menu ul li.stores {
  display: none;
}

.menu ul li.franchisee {
  display: none;
}

.menu ul li.custom {
  display: none;
}

.menu ul li.bulk {
  display: none;
}

.menu ul li ul li {
  font-size: 0.8125rem;
  overflow: hidden;
  zoom: 1;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.menu ul li ul li a {
  display: block;
  padding: 4px 0;
}

.menu ul li ul li a:before {
  display: none;
}

.menu ul li:first-child a {
  margin-left: 0;
}

.menu ul li:last-child a {
  margin-right: 0;
}

.menu ul .last-menu a {
  color: #e57200;
}

.menu ul li.menu-hover > a:before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  height: 2px;
  width: 100%;
  background: #e57200;
}

.menu .Office-menu,
.menu .decor-menu {
  margin-top: 10px;
}

.menu .Office-menu i,
.menu .decor-menu i {
  height: 55px;
}

.menu i {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  float: left;
  margin-top: -2px;
}

.menu .heading {
  display: block;
  text-transform: uppercase !important;
  color: #000;
  margin-bottom: 7px;
  font-family: 'robotomedium';
}

.menu .heading:hover {
  color: #535353 !important;
}

.menu .heading img {
  display: block;
}

.menu article {
  padding: 0px 2% 20px;
  margin: 25px 0 0;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.menu article.decor-flex {
  -webkit-box-flex: 2.5;
  -ms-flex: 2.5;
  flex: 2.5;
}

.menu article.decor-flex .flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.menu article.decor-flex .col {
  width: 50%;
}

.menu article + article {
  border-left: 1px solid #eae4e2;
  min-height: 245px;
}

.menu figure {
  float: right;
  width: 310px;
  margin: 0;
  padding: 35px 40px 0px 0px;
  text-align: right;
}

.menu figure.width-one {
  width: 310px;
}

.menu figure img {
  height: auto;
}

.menu figure.width-two {
  width: 223px;
}

.menu figure.width-two a {
  padding-top: 0px;
}

.menu figure.width-three {
  width: 650px;
}

.menu figure.width-decor {
  width: auto;
}

.menu .options {
  width: 25%;
  border: none;
  color: #333333;
  text-align: center;
}

.menu .options .title {
  font-size: 1rem;
  padding-top: 5px;
}

.menu .options .title span {
  font-family: 'robotomedium';
  font-size: 1rem;
}

.menu .options span {
  font-size: 1.5625rem;
  font-family: 'robotobold';
}

.menu .options small {
  font-size: 0.8125rem;
  display: block;
  margin-top: 5px;
}

.menu .options .btn-view {
  border: 1px solid #a1a1a1;
  padding: 10px 0;
  margin-top: 15px;
}

.menu .options a:hover {
  color: inherit;
}

.menu .mattress ul {
  padding-bottom: 10px;
}

.menu .mattress article {
  width: 200px;
  padding: 0px 20px 20px;
  min-height: 240px;
}

.menu .mattress .last {
  width: 220px;
}

.menu .mattress .main_menu {
  min-height: auto;
}

.menu .mattress .width-one {
  width: 320px;
  padding: 15px 15px 0px;
}

.menu .mattress .width-one a {
  display: inline-block;
}

.menu .mattress img {
  margin: 0px;
}

.menu .mattress figure a {
  margin-left: 10px;
  padding: 0px;
}

.menu > ul > li .main_menu .bospace {
  margin-top: 20px;
}

.menu > ul > li > ul {
  width: 100%;
  top: 100%;
  background: #fff;
  padding: 0px 0px 10px;
  position: absolute;
  z-index: 9999;
  left: 0;
  margin: 0;
  list-style: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #f1f1f1;
  display: none;
  -webkit-box-shadow: 0 4px 7.28px 0.72px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 7.28px 0.72px rgba(0, 0, 0, 0.2);
}

.menu ul li ul li img {
  margin-bottom: 10px;
}

.main_menu {
  min-height: 190px;
}

.main_menu .boicon-space {
  margin-top: 28px;
}

.main_menu.package {
  padding-bottom: 0;
  min-height: 380px;
}

.menu > ul > li .mattress_nav,
.menu > ul > li .sofa_nav,
.menu > ul > li .study_nav {
  width: 80%;
}

.menu > ul > li .mattress_nav,
.menu > ul > li .study_nav {
  left: 9%;
}

.menu-mobile {
  display: none;
  padding: 20px;
}

@media only screen and (max-width: 768px) {
  .menu .menu-show .salemenus span {
    left: 0;
    top: -6px;
    padding: 0px 4px;
  }
}

.tab-content .tab {
  display: none;
}

.tab-content .tab.active {
  display: block;
}

.about-woodenstreet,
.common-text {
  border: 1px solid #e5e5e5;
  padding: 15px 20px;
  margin: 30px 0;
  height: 160px;
  overflow: auto;
  font-size: 12px;
  line-height: 20px;
  color: #999999;
  margin-left: 0;
  text-align: justify;
}

.about-woodenstreet h2,
.common-text h2 {
  color: #e07a32;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  margin-bottom: 3px;
  text-align: center;
  display: block;
  padding-top: 3px;
}

.about-woodenstreet p,
.common-text p {
  font-size: 0.75rem;
  color: #646464;
  line-height: 20px;
  padding: 8px 0px;
  text-align: justify;
}

.about-woodenstreet a,
.common-text a {
  color: #e57200;
}

.about-woodenstreet h2,
.about-woodenstreet h3,
.common-text h2,
.common-text h3 {
  text-align: center;
  margin: 0;
  font-weight: normal;
  font-size: 1.25rem;
}

.about-woodenstreet h3,
.common-text h3 {
  text-align: left;
  font-size: 1rem;
}

.about-woodenstreet ol,
.about-woodenstreet ul,
.common-text ol,
.common-text ul {
  padding: 0;
  list-style-position: inside;
  color: #646464;
}

.about-woodenstreet ol li,
.about-woodenstreet ul li,
.common-text ol li,
.common-text ul li {
  display: block;
  padding-bottom: 5px;
  position: relative;
  padding-left: 15px;
  font-family: 'pangramregular';
  font-size: 13px;
}

.about-woodenstreet ol li:before,
.about-woodenstreet ul li:before,
.common-text ol li:before,
.common-text ul li:before {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #646464;
  margin-right: 5px;
  position: absolute;
  left: 0;
  top: 8px;
}

.about-woodenstreet ol a,
.about-woodenstreet ul a,
.common-text ol a,
.common-text ul a {
  color: #e57200;
}

.mCustomScrollbar {
  -ms-touch-action: none;
  touch-action: none;
}

.mCustomScrollbar.mCS_no_scrollbar {
  -ms-touch-action: auto;
  touch-action: auto;
}

.mCustomScrollBox {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: 0;
  direction: ltr;
}

.mCSB_container {
  overflow: hidden;
  width: auto;
  height: auto;
}

.mCSB_inside > .mCSB_container {
  margin-right: 20px;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-left: 30px;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0;
}

.mCSB_scrollTools {
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.75;
}

.mCSB_outside + .mCSB_scrollTools {
  right: -26px;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  right: auto;
  left: 0;
}

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px;
}

.mCSB_scrollTools .mCSB_draggerContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
}

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0;
}

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  border-radius: 16px;
}

.mCSB_scrollTools .mCSB_dragger {
  cursor: pointer;
  width: 100%;
  height: 30px;
  z-index: 1;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  position: relative;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  border-radius: 16px;
  text-align: center;
}

.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
}

.autocomplete-suggestions {
  text-align: left;
  cursor: default;
  border: 1px solid #ccc;
  border-top: 0;
  background: #fff;
  -webkit-box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.1);
  position: absolute;
  display: none;
  z-index: 9999;
  max-height: 254px;
  overflow: hidden;
  overflow-y: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.autocomplete-suggestion {
  position: relative;
  padding: 4px 10px;
  line-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #333;
  border-bottom: 1px solid #e5e5e5;
  text-transform: capitalize;
}

.autocomplete-suggestion b {
  font-weight: normal;
  color: #1f8dd6;
}

.autocomplete-suggestion.selected {
  background: #f0f0f0;
}

.request-call {
  position: fixed;
  right: 0;
  top: 0;
  top: 230px;
  width: 65px;
  outline: none;
  z-index: 9;
}

.small-fixed-popup {
  position: fixed;
  bottom: 10px;
  left: 1px;
  z-index: 9;
  cursor: pointer;
}

.small-fixed-popup .close-btn {
  position: absolute;
  right: 20px;
  top: 8px;
  width: 23px;
  height: 23px;
  background: url('/images/common/close.png') no-repeat;
  cursor: pointer;
}

.reg-call {
  background: #fff;
  padding: 25px 20px;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  border-radius: 5px;
  text-align: center;
  width: 500px !important;
}

.reg-call .lead {
  color: #596470;
  padding: 2px 0px 5px;
  font-size: 14px;
}

.reg-call .number {
  font-size: 20px;
  color: #435471;
  font-family: robotomedium;
  font-weight: 400;
  padding-bottom: 17px;
}

.reg-call .number:before {
  width: 20px;
  height: 20px;
  display: inline-block;
  content: '';
  margin-right: 8px;
  vertical-align: middle;
}

.reg-call i {
  background: #fff;
  font-size: 13px;
  font-family: robotomedium;
  color: #b8c0c9;
  font-weight: 400;
  padding: 0px 8px;
  display: block;
  font-style: normal;
  margin: auto;
  z-index: 9;
  position: relative;
  width: 34px;
}

.reg-call hr {
  margin: 0px;
  border-top: 1px solid #dbdee3;
  height: 0px;
  margin-top: -13px;
}

.reg-call span {
  font-size: 20px;
  color: #1d2228;
  font-weight: 400;
  display: block;
}

.reg-call form {
  margin: 0 -1%;
}

.reg-call .field {
  display: inline-block;
  vertical-align: top;
  width: 48%;
  margin: 15px 1% 0;
  border-radius: 3px;
  text-align: left;
}

.reg-call .field__input {
  color: #000;
  border: 1px solid #d9d9d9;
}

.reg-call .field__input::-webkit-input-placeholder {
  color: #707070;
}

.reg-call .field__input::-moz-placeholder {
  color: #707070;
}

.reg-call .field__input:-ms-input-placeholder {
  color: #707070;
}

.reg-call .field__input::-ms-input-placeholder {
  color: #707070;
}

.reg-call .field__input::placeholder {
  color: #707070;
}

.reg-call .full {
  width: 98%;
}

.reg-call .full .field__input {
  height: 70px;
  padding-top: 10px;
  resize: vertical;
}

.reg-call [type='button'],
.reg-call [type='submit'] {
  border: 0;
  outline: 0;
  text-transform: uppercase;
  cursor: pointer;
  margin: 0px auto 0;
  padding: 13px 0;
  background: #e57200;
  color: #fff;
  font-size: 1.125rem;
  font-family: robotomedium;
  font-weight: 400;
  width: 98%;
  border-radius: 3px;
  margin: 15px 0 0;
}

.reg-call [type='button'].buttonload:before,
.reg-call [type='submit'].buttonload:before {
  top: 15px;
}

.reg-call .red-placeholder {
  border: 1px solid #f00 !important;
}

.reg-call .red-placeholder.placeholder {
  color: #f00;
}

.reg-call .red-placeholder:-moz-placeholder {
  color: #f00;
}

.reg-call .red-placeholder::-moz-placeholder {
  color: #f00;
}

.reg-call .red-placeholder:-ms-input-placeholder {
  color: #f00;
}

.reg-call .red-placeholder::-webkit-input-placeholder {
  color: #f00;
}

.reg-call .request-success {
  color: #00be0d;
  padding: 0;
}

.reg-call .antispam {
  height: auto;
  padding: 0;
}

.reg-call .swpmodal-close {
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
}

.reqcall-popup.box-modal {
  background: none;
}

.g-hidden {
  display: none;
}

.call-fixed {
  display: none;
}

/* common-css 14-05-2024 */

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.container-1100 {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0px 10px;
}

.container-1200 {
  max-width: 1240px;
  margin: 0 auto;
}

.container-1240 {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0px 10px;
}

.container-1250 {
  max-width: 1250px;
  margin: 0 auto;
  padding: 0px 10px;
}

.container-1300 {
  max-width: 1320px;
  margin: 0 auto;
  padding: 0px 10px;
}

.container-1600 {
  max-width: 1600px;
  margin: 0px auto;
}

.home-container-1600 {
  max-width: 1500px;
  margin: 0px auto;
}

@media (max-width: 1366px) {
  .home-container-1600 {
    max-width: 1240px;
  }
}

@media (max-width: 1250px) {
  .home-container-1600 {
    max-width: 1150px;
    width: 100%;
  }
}

.container,
.containerFluid,
.containerlg,
.containermd,
.containersm,
.containerxl,
.containerxxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .containersm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .containermd,
  .containersm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .containerlg,
  .containermd,
  .containersm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .containerlg,
  .containermd,
  .containersm,
  .containerxl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container,
  .containerlg,
  .containermd,
  .containersm,
  .containerxl,
  .containerxxl {
    max-width: 1320px;
  }
}

.wdRow {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}

.mx-0 {
  margin-right: 0px;
  margin-left: 0px;
}

.wdRow > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.wdCol {
  flex: 1 0 0%;
}

.wdRow-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.wdRow-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.wdRow-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.wdRow-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.wdRow-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.wdRow-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.wdRow-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

@media (min-width: 576px) {
  .wdCol-sm {
    flex: 1 0 0%;
  }

  .wdRow-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .wdRow-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .wdRow-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .wdRow-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .wdRow-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .wdRow-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .wdRow-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

@media (min-width: 768px) {
  .wdCol-md {
    flex: 1 0 0%;
  }

  .wdRow-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .wdRow-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .wdRow-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .wdRow-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .wdRow-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .wdRow-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .wdRow-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

@media (min-width: 992px) {
  .wdCol-lg {
    flex: 1 0 0%;
  }

  .wdRow-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .wdRow-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .wdRow-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .wdRow-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .wdRow-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .wdRow-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .wdRow-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

@media (min-width: 1200px) {
  .wdCol-xl {
    flex: 1 0 0%;
  }

  .wdRow-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .wdRow-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .wdRow-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .wdRow-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .wdRow-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .wdRow-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .wdRow-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

@media (min-width: 1400px) {
  .wdCol-xxl {
    flex: 1 0 0%;
  }

  .wdRow-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .wdRow-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .wdRow-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .wdRow-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .wdRow-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .wdRow-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .wdRow-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

.wdCol-auto {
  flex: 0 0 auto;
  width: auto;
}

.wdCol-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.wdCol-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.wdCol-3 {
  flex: 0 0 auto;
  width: 25%;
}

.wdCol-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.wdCol-6 {
  flex: 0 0 auto;
  width: 50%;
}

.wdCol-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.wdCol-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.wdCol-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.wdCol-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.wdCol-12 {
  flex: 0 0 auto;
  width: 100%;
}

.wdg-0,
.wdgx-0 {
  --bs-gutter-x: 0;
}

.wdg-0,
.wdgy-0 {
  --bs-gutter-y: 0;
}

.wdg-1,
.wdgx-1 {
  --bs-gutter-x: 0.25rem;
}

.wdg-1,
.wdgy-1 {
  --bs-gutter-y: 0.25rem;
}

.wdg-2,
.wdgx-2 {
  --bs-gutter-x: 10px;
}

.wdg-2,
.wdgy-2 {
  --bs-gutter-y: 0.5rem;
}

.wdg-3,
.wdgx-3 {
  --bs-gutter-x: 1rem;
}

.wdg-3,
.wdgy-3 {
  --bs-gutter-y: 1rem;
}

.wdg-4,
.wdgx-4 {
  --bs-gutter-x: 1.5rem;
}

.wdg-4,
.wdgy-4 {
  --bs-gutter-y: 20px;
}

.wdg-30,
.wdgx-30 {
  --bs-gutter-x: 30px;
}

.wdg-30,
.wdgy-30 {
  --bs-gutter-y: 30px;
}

.wdg-5,
.wdgx-5 {
  --bs-gutter-x: 3rem;
}

.wdg-5,
.wdgy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .wdCol-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .wdCol-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .wdCol-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .wdCol-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .wdCol-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .wdCol-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .wdCol-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .wdCol-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .wdCol-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .wdCol-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .wdCol-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .wdCol-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .wdCol-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .wdg-sm-0,
  .wdgx-sm-0 {
    --bs-gutter-x: 0;
  }

  .wdg-sm-0,
  .wdgy-sm-0 {
    --bs-gutter-y: 0;
  }

  .wdg-sm-1,
  .wdgx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .wdg-sm-1,
  .wdgy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .wdg-sm-2,
  .wdgx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .wdg-sm-2,
  .wdgy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .wdg-sm-3,
  .wdgx-sm-3 {
    --bs-gutter-x: 12px;
  }

  .wdg-sm-3,
  .wdgy-sm-3 {
    --bs-gutter-y: 12px;
  }

  .wdg-sm-4,
  .wdgx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .wdg-sm-4,
  .wdgy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .wdg-sm-5,
  .wdgx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .wdg-sm-5,
  .wdgy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .wdCol-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .wdCol-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .wdCol-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .wdCol-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .wdCol-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .wdCol-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .wdCol-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .wdCol-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .wdCol-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .wdCol-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .wdCol-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .wdCol-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .wdCol-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .wdg-md-0,
  .wdgx-md-0 {
    --bs-gutter-x: 0;
  }

  .wdg-md-0,
  .wdgy-md-0 {
    --bs-gutter-y: 0;
  }

  .wdg-md-1,
  .wdgx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .wdg-md-1,
  .wdgy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .wdg-md-2,
  .wdgx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .wdg-md-2,
  .wdgy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .wdg-md-3,
  .wdgx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .wdg-md-3,
  .wdgy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .wdg-md-4,
  .wdgx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .wdg-md-4,
  .wdgy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .wdg-md-20,
  .wdgx-md-20 {
    --bs-gutter-x: 1.5rem;
  }

  .wdg-md-20,
  .wdgy-md-20 {
    --bs-gutter-y: 1.5rem;
  }

  .wdg-md-5,
  .wdgx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .wdg-md-5,
  .wdgy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .wdCol-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .wdCol-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .wdCol-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .wdCol-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .wdCol-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .wdCol-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .wdCol-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .wdCol-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .wdCol-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .wdCol-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .wdCol-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .wdCol-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .wdCol-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .wdg-lg-0,
  .wdgx-lg-0 {
    --bs-gutter-x: 0;
  }

  .wdg-lg-0,
  .wdgy-lg-0 {
    --bs-gutter-y: 0;
  }

  .wdg-lg-1,
  .wdgx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .wdg-lg-1,
  .wdgy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .wdg-lg-2,
  .wdgx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .wdg-lg-2,
  .wdgy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .wdg-lg-3,
  .wdgx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .wdg-lg-3,
  .wdgy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .wdg-lg-4,
  .wdgx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .wdg-lg-4,
  .wdgy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .wdg-lg-5,
  .wdgx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .wdg-lg-5,
  .wdgy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .wdCol-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .wdCol-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .wdCol-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .wdCol-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .wdCol-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .wdCol-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .wdCol-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .wdCol-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .wdCol-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .wdCol-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .wdCol-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .wdCol-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .wdCol-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .wdg-xl-0,
  .wdgx-xl-0 {
    --bs-gutter-x: 0;
  }

  .wdg-xl-0,
  .wdgy-xl-0 {
    --bs-gutter-y: 0;
  }

  .wdg-xl-1,
  .wdgx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .wdg-xl-1,
  .wdgy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .wdg-xl-2,
  .wdgx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .wdg-xl-2,
  .wdgy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .wdg-xl-3,
  .wdgx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .wdg-xl-3,
  .wdgy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .wdg-xl-4,
  .wdgx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .wdg-xl-4,
  .wdgy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .wdg-xl-5,
  .wdgx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .wdg-xl-5,
  .wdgy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .wdCol-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .wdCol-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .wdCol-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .wdCol-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .wdCol-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .wdCol-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .wdCol-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .wdCol-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .wdCol-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .wdCol-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .wdCol-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .wdCol-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .wdCol-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .wdg-xxl-0,
  .wdgx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .wdg-xxl-0,
  .wdgy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .wdg-xxl-1,
  .wdgx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .wdg-xxl-1,
  .wdgy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .wdg-xxl-2,
  .wdgx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .wdg-xxl-2,
  .wdgy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .wdg-xxl-3,
  .wdgx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .wdg-xxl-3,
  .wdgy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .wdg-xxl-4,
  .wdgx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .wdg-xxl-4,
  .wdgy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .wdg-xxl-5,
  .wdgx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .wdg-xxl-5,
  .wdgy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

/* btn */
.text-link-primary {
  color: #e57200;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.btn {
  margin: 5px;
}

.btn-primary {
  background-color: var(--bg-orange-btn);
  border-color: var(--bg-orange-btn);
  color: #fff;
  font-size: 17px;
  line-height: 22px;
  font-weight: 500;
  font-family: 'pangramregular';
  padding: 10px 20px;
  border-radius: 4px;
  letter-spacing: 0.02em;
  display: inline-block;
  text-align: center;
  transition: 0.3s ease-in;
}

.btn-primary img {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.btn-primary:hover {
  background-color: var(--bg-orange-btn-hover);
  color: #fff;
}

.view-more-white-btn {
  border: 1px solid #acacac;
  border-radius: 3px;
  padding: 12px 22px;
  font-family: 'pangramregular';
  background-color: #ffffff;
  font-size: 0.875rem;
  line-height: 17px;
  font-weight: 400;
  transition: 0.3s all ease-in-out;
  color: #333;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  margin: 4px 0px;
}

.view-more-white-btn img {
  margin-left: 3px;
  margin-top: -2px;
}

.view-more-white-btn:hover {
  color: #333333;
  border: 1px solid #8b8b8b;
}

.view-all-btn {
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 10px 15px;
  font-family: 'pangrammedium';
  background-color: #f5f5f5;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  transition: 0.3s all ease-in-out;
  color: #333333;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  margin: 4px 0px;
  text-transform: uppercase;
}

.view-cutr-story {
  border: 1px solid #646464;
  border-radius: 5px;
  font-size: 15px;
}

.secondary-btn {
  background-color: #333;
  color: #fff;
  padding: 10px 15px;
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  font-family: pangramregular;
  letter-spacing: 0.02em;
  border-radius: 3px;
  transition: 0.3s all ease-in-out;
  text-align: center;
}

.secondary-btn:hover {
  background-color: #515151;
}

.category-more-link {
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  border: 0px;
}

.category-more-link:hover {
  background-color: transparent;
  border-radius: 0px;
}

.view-all-btn img {
  margin-left: 3px;
}

.view-all-btn:hover {
  color: #333333;
  border: 1px solid #8b8b8b;
}

.viewall-link {
  color: #333333;
  font-family: 'pangramregular';
  padding: 10px 0 0;
  font-size: 15px;
  line-height: normal;
  display: inline-flex;
  align-items: center;
  font-weight: normal;
  transition: 0.3s all ease-in-out;
}

.viewall-link .view-btn-arrow-hover {
  display: none;
}

.viewall-link:hover .view-btn-arrow-hover {
  display: block;
}

.viewall-link:hover .view-btn-arrow {
  display: none !important;
}

.viewall-link svg {
  margin-left: 4px;
}

.viewall-link img {
  margin-left: 4px;
  transition: 0.3s all ease-in-out;
}

.viewall-link:hover {
  color: #e57200;
}

.viewall-link:hover img {
  margin-left: 6px;
  color: #e57200;
}

.quickview-btn {
  padding: 6px 12px;
  border-radius: 3px;
  font-size: 13px;
  line-height: 16px;
}

.btn-upto {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  border-radius: 2px;
  text-transform: uppercase;
  padding: 7px 30px;
}

.btn-find-store {
  font-size: 14px;
  line-height: 16px;
  padding: 10px 26px;
}

.btn-border {
  background-color: #fff;
  border: 1px solid #d1d1d1;
  color: #333;
  font-size: 16px;
  line-height: 22px;
  font-family: 'pangrammedium';
  padding: 10px 20px;
  border-radius: 3px;
  letter-spacing: 0.02em;
  display: inline-flex;
  text-align: center;
  align-items: center;
  transition: 0.3s ease-in;
}

.btn-border img {
  margin-right: 2px;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 575px) {
  .btn-upto {
    font-size: 16px;
    line-height: 24px;
    padding: 7px 20px;
  }

  .btn-border {
    font-size: 0.875rem;
    line-height: 20px;
  }

  .btn-primary {
    font-size: 15px;
    line-height: 22px;
    padding: 9px 15px;
  }

  .view-all-btn {
    padding: 10px 12px;
    font-size: 14px;
    line-height: 20px;
  }

  .view-cutr-story {
    font-size: 15px;
  }
}

/* common-css */
/* slider-arrow common */

.swiperDiv {
  .swiper-button-next:after,
  .swiper-button-prev:after {
    display: none;
  }

  .swiper-button-prev:before {
    content: '\f053';
    display: block;
    font-family: 'fontawesome';
    font-size: 18px;
    color: #878787;
  }

  .swiper-button-next:before {
    content: '\f054';
    display: block;
    font-family: 'fontawesome';
    font-size: 18px;
    color: #878787;
  }
}

.swiper-button-prev,
.swiper-button-next {
  transform: translate(0, -50%);
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  pointer-events: revert !important;
  cursor: pointer !important;
}

.disabled-slider-arrow .swiper-button-prev.swiper-button-disabled,
.disabled-slider-arrow .swiper-button-next.swiper-button-disabled {
  opacity: 0;
}

.swiper-button-prev {
  left: 0px !important;
}

.swiper-button-next {
  right: 0px !important;
}

.swiper-button-next:after {
  background: url(/images/client/CaretRight.svg);
  content: '';
  background-color: #fff;
  background-repeat: no-repeat;
  width: 35px;
  min-width: 35px;
  height: 35px;
  background-size: 35px;
  font-size: 0px !important;
}

.swiper-button-prev:after {
  background: url(/images/client/CaretLeft.svg);
  content: '';
  background-color: #fff;
  background-repeat: no-repeat;
  width: 35px;
  min-width: 35px;
  height: 35px;
  background-size: 35px;
  font-size: 0px !important;
}

.similar-modal-slider .swiper-button-prev:after {
  background-image: url(/images/category/modal-left-arrow.svg);
  content: '';
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: center;
}

.similar-modal-slider .swiper-button-next:after {
  background-image: url(/images/category/modal-right-arrow.svg);
  content: '';
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: center;
}

.similar-modal-slider .swiper-button-next,
.similar-modal-slider .swiper-button-prev {
  transform: translate(0, -36%) !important;
  top: 36% !important;
}

.new-arrivals-slider .swiper-button-prev.swiper-button-disabled,
.new-arrivals-slider .swiper-button-next.swiper-button-disabled {
  opacity: 0.5;
}

.see-insp-slider .swiper-button-next,
.see-insp-slider .swiper-button-prev {
  transform: translate(0, -45%) !important;
  top: 45% !important;
}

.see-insp-slider .swiper-button-next:after,
.see-insp-slider .swiper-button-prev:after {
  border-radius: 2px;
}

.other-store-slider .swiper-button-next,
.other-store-slider .swiper-button-prev {
  transform: translate(0, -48%) !important;
  top: 48% !important;
}

.slider-arrow-outside .swiper-button-next:after,
.slider-arrow-outside .swiper-button-prev:after {
  background-color: transparent;
}

.slider-arrow-outside .swiper {
  padding: 0px 30px;
}

.slider-arrow-outside .swiper:after {
  background-color: #f5f5f5;
  height: 100%;
  width: 27px;
  right: 0px;
  content: '';
  position: absolute;
  top: 0px;
  z-index: 2;
}

.slider-arrow-outside .swiper:before {
  background-color: #f5f5f5;
  height: 100%;
  width: 27px;
  left: 0px;
  content: '';
  position: absolute;
  top: 0px;
  z-index: 2;
}

/* slider-arrow common*/
/* seller-slider-arrow */

.sellerslider .swiper-button-prev,
.sellerslider .swiper-button-next {
  transform: translate(0, -48%);
  top: 48%;
}

.bannerswiperslider .swiper-button-prev,
.bannerswiperslider .swiper-button-next {
  transform: translate(0, -53%);
  top: 53%;
}

/* seller-slider-arrow */
.shop-sofa-size-slider .swiper-button-prev,
.shop-sofa-size-slider .swiper-button-next {
  transform: translate(0, -46%);
  top: 46%;
}

/* customer-story */

/* tab */
.sofa-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sofa-tabs li .tab-btn {
  font-size: 15px;
  line-height: 20px;
  color: #515151;
  font-family: pangrammedium;
  letter-spacing: 0.02em;
  border: 1px solid #8b8b8b;
  display: block;
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 7px 10px;
  border-radius: 50px;
  transition: 0.3s all ease-in-out;
  text-align: center;
}

.sofa-tabs li .tab-btn:hover {
  background-color: #f5f5f5;
}

.sofa-tabs li .tab-btn.active {
  color: #fff;
  background: #e27a34;
  border: 1px solid #e27a34;
}

@media screen and (max-width: 767px) {
  .sofa-tabs {
    display: block;
    white-space: nowrap;
    overflow: auto;
  }
  .sofa-tabs::-webkit-scrollbar {
    display: none;
  }
  .sofa-tabs li {
    display: inline-block;
  }
  .sofa-tabs li .tab-btn {
    font-size: 14px;
    line-height: 18px;
    padding: 5px 12px;
    font-family: 'pangramregular';
  }
}
/* tab */
.anniva-mobile-slider .swiper {
  padding-bottom: 30px;
}

.anniva-mobile-slider
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #e27a34;
  opacity: 1;
}

.anniva-mobile-slider .swiper-pagination-bullet {
  background-color: #707070;
  opacity: 0.25;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

/* all pages content section css */

.content-common {
  width: 96%;
  margin: 0 auto;
  padding: 0px 20px;
}

.content-common h2 {
  padding: 10px 0px 6px;
  margin: 0;
  letter-spacing: 0.015em;
  color: #333333;
  font-family: 'pangrammedium';
  line-height: 23px;
  font-size: 19px;
  font-weight: 500;
}

.content-common p {
  font-size: 0.9375rem;
  line-height: 22px;
  margin: 0 0 10px;
  color: #646464;
  font-weight: 400;
  text-align: justify;
  font-family: 'pangramregular';
  letter-spacing: 0.5px;
}

.content-common p strong {
  color: #333333;
  font-weight: 500;
  font-family: 'pangrammedium';
}

.content-common p strong a {
  margin-bottom: 3px;
  display: inline-block;
}

.content-common a {
  color: #e57200;
}

.content-common h3,
.content-common h4,
.content-common h5,
.content-common h6 {
  color: #333333;
  line-height: 21px;
  font-size: 17px;
  font-weight: 500;
  padding: 10px 0px 6px;
  margin: 0;
  letter-spacing: 0.015em;
  font-family: 'pangrammedium';
}

.content-common ol li {
  font-size: 0.9375rem;
  line-height: 22px;
  margin: 0 0 10px;
  color: #515151;
  font-weight: 400;
  text-align: justify;
  font-family: 'pangramregular';
  letter-spacing: 0.5px;
}

.content-common ol li strong {
  color: #333333;
  font-family: 'pangrammedium';
}

.content-common ul li {
  font-size: 0.9375rem;
  line-height: 22px;
  margin: 0 0 10px;
  color: #515151;
  font-weight: 400;
  text-align: justify;
  font-family: 'pangramregular';
  letter-spacing: 0.5px;
}

.content-common ul li strong {
  color: #333333;
  font-family: 'pangrammedium';
}

.content-common table {
  border-collapse: collapse;
  text-align: left;
}

.content-common table thead th {
  font-size: 13px;
  line-height: 14.4px;
  color: #646464;
  font-weight: 400;
  font-family: 'pangramregular';
  letter-spacing: 0.2px;
  padding: 6px 10px;
  border: 1px solid #e7e7e7;
}

.content-common table tbody td {
  font-size: 13px;
  line-height: 15px;
  color: #646464;
  font-weight: 400;
  font-family: 'pangramregular';
  letter-spacing: 0.2px;
  padding: 6px 10px;
  border: 1px solid #c1c1c1;
}

.sofa-detailtable {
  margin: 0 0 0 30px;
  float: right;
  max-width: 420px;
  width: 100%;
}

.content-common .data-update {
  font-size: 13px;
  line-height: 15px;
  color: #333333;
  font-family: 'pangrammedium';
  padding: 10px 0px;
  display: block;
}

.content-commobig h2 {
  padding: 10px 0px 6px;
  margin: 0;
  letter-spacing: 0.015em;
  color: #333333;
  font-family: 'pangrammedium';
  line-height: 23px;
  font-size: 19px;
  font-weight: 500;
}

.content-commobig p {
  font-size: 15px;
  line-height: 22px;
  margin: 0 0 10px;
  color: #646464;
  font-weight: 400;
  text-align: justify;
  font-family: 'pangramregular';
  letter-spacing: 0.5px;
}

.content-commobig h3,
.content-commobig h4,
.content-commobig h5,
.content-commobig h6 {
  color: #333333;
  line-height: 21px;
  font-size: 17px;
  font-weight: 500;
  padding: 10px 0px 6px;
  margin: 0;
  letter-spacing: 0.015em;
  font-family: 'pangrammedium';
}

.content-commobig ol li {
  font-size: 15px;
  line-height: 22px;
  margin: 0 0 10px;
  color: #515151;
  font-weight: 400;
  text-align: justify;
  font-family: 'pangramregular';
  letter-spacing: 0.5px;
}

.content-commobig ul li {
  font-size: 15px;
  line-height: 22px;
  margin: 0 0 10px;
  color: #515151;
  font-weight: 400;
  text-align: justify;
  font-family: 'pangramregular';
  letter-spacing: 0.5px;
}

/* all pages content section css */
.justify-content-center {
  justify-content: center;
}

@media (max-width: 767px) {
  .home-container-1600 {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 10px;
  }
  .content-common {
    width: 100%;
    padding: 20px 10px 10px;
  }

  .content-common h2 {
    margin: 5px 0;
    font-size: 1rem;
    line-height: 20px;
    font-weight: normal;
    padding: 0px;
  }

  .content-common p {
    font-size: 0.8125rem;
    line-height: 18px;
    color: #515151;
    text-align: justify;
    margin: 0 0 12px;
    word-break: break-word;
  }

  .content-common h3,
  .content-common h4,
  .content-common h5,
  .content-common h6 {
    margin: 5px 0;
    font-size: 1rem;
    line-height: 20px;
    font-weight: normal;
    padding: 0px;
  }
  .container-1200 {
    max-width: 100%;
    padding: 0px 10px;
  }

  .content-common ol {
    padding: 0px 12px;
  }

  .content-common ol li {
    font-size: 0.8125rem;
    line-height: 18px;
  }

  .content-common ol li strong {
    color: #333333;
    font-family: 'pangrammedium';
  }

  .content-common ul {
    margin-left: 25px !important;
  }

  .content-common ul li {
    font-size: 0.8125rem;
    line-height: 18px;
    color: #515151;
  }

  .sofa-detailtable {
    max-width: 100%;
    float: none;
    margin-left: 0px;
  }

  .content-common .sofa-detailtable table {
    width: 100% !important;
  }

  .d-none-sm {
    display: none !important;
  }

  .see-insp-slider .swiper-button-next,
  .see-insp-slider .swiper-button-prev {
    display: none;
  }

  .content-common .common-details table tbody td a {
    color: #515151;
  }
}

.product-add-card-slider .swiper-thumbs .swiper-slide {
  margin-right: 0px !important;
  overflow: hidden;
  margin-top: 10px;
  margin-right: 6px !important;
  width: 85px !important;
  height: 69px !important;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  transition: 0.3s all ease;
}

.product-add-card-slider .swiper-thumbs .swiper-slide:hover {
  border-color: #acacac;
}

.product-add-card-slider .swiper-thumbs .swiper-slide img {
  object-fit: cover;
  width: 100%;
  /* height: auto !important; */
  height: auto;
}

.product-add-card-slider .swiper-slide-thumb-active {
  border-color: #e27a34 !important;
}

.product-add-card-slider .swiper-button-next:after {
  background-image: url(/images/category/modal-right-arrow.svg);
  background-repeat: no-repeat;
  background-size: 12px 17px;
  background-color: transparent;
  background-position: center;
  background-color: #ffffffd1;
  width: 30px;
  min-width: 30px;
}

.product-add-card-slider .swiper-button-prev:after {
  background-image: url(/images/category/modal-left-arrow.svg);
  background-repeat: no-repeat;
  background-size: 12px 17px;
  background-color: transparent;
  background-position: center;
  background-color: #ffffffd1;
  width: 30px;
  min-width: 30px;
}

.product-add-card-slider .swiper-thumbs .swiper-wrapper {
  flex-wrap: wrap;
}

.product-right-slider {
  position: sticky;
  top: 0px;
}

.product-right-slider .swiper-button-prev,
.product-right-slider .swiper-button-next {
  top: 54%;
  transform: translate(0, -54%);
}

/* heade */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -969px;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  overflow: scroll;
  background: #ffffff;
  transition: all 0.7s ease;
  padding-bottom: 0px;
}

.sidebar.sidebar-add {
  transition: all 0.7s ease;
  left: 0;
}

/* heade */
/* Accordion */
.product-link-name .szh-accordion__item-heading {
  font-size: 15px;
  line-height: 18px;
  font-family: 'pangrammedium';
  color: #333333;
  font-weight: 400;
  position: relative;
  padding: 20px 16px;
  border-top: 1px solid #e5e5e5;
  transition: all 0.5s ease;
}

.szh-accordion__item-content {
  transition: all 0.5s ease;
}

.product-link-name .szh-accordion__item-heading button {
  padding: 0px;
  width: 100%;
  text-align: left;
  display: flex;
  color: #333333;
  justify-content: space-between;
  font-family: 'pangrammedium';
  letter-spacing: 0.02em;
}

.szh-accordion__item-heading button img {
  margin-left: 8px;
  transition: all 0.3s ease;
  width: 12px;
  opacity: 59%;
}
.cart-login .szh-accordion__item-heading button img {
  margin-left: 8px;
  transition: all 0.3s ease;
  width: 24px;
  opacity: 1;
}

.szh-accordion__item--expanded .szh-accordion__item-heading img {
  transform: rotate(180deg);
}

/* Accordion */
/* radio */
.SelectBox {
  display: flex;
}

.SelectBox label {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  font-family: 'pangramregular';
  color: #333333;
  display: flex;
  align-items: center;
  margin: 0 0 0 15px;
  position: relative;
  cursor: pointer;
  padding: 0 0 0 20px;
}

.SelectBox label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.SelectBox label input.formcheck {
  margin-right: 5px;
}

.SelectBox label .checkmarkbtn {
  position: absolute;
  top: 3px;
  left: 0px;
  height: 16px;
  width: 16px;
  background-color: #fefefe;
  border: 1px solid #e57200;
  border-radius: 50%;
}

.SelectBox label .checkmarkbtn:after {
  content: '';
  position: absolute;
  display: none;
  top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #e57200;
}

.SelectBox label input:checked ~ .checkmarkbtn:after {
  display: block;
}

.faq-side-details .accordion_in {
  border-bottom: 1px solid #e5e5e5;
}

.faq-side-details .acc_head {
  font-size: 14px;
  cursor: pointer;
  position: relative;
  font-weight: 400;
  padding: 10px 10px 10px 30px;
  display: block;
  font-family: pangramregular;
  color: #333;
}

.faq-side-details .acc_content {
  padding-left: 40px;
  padding-bottom: 10px;
}

.faq-side-details .acc_content p {
  font-size: 0.8125rem;
  color: #646464;
  line-height: 22px;
  margin: 0 0 10px;
  font-family: 'pangramregular';
  letter-spacing: 0.01rem;
}

.faq-side-details .acc_icon_expand {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background: #fff;
  border: 1px solid #e5e5e5;
  position: absolute;
  left: 0;
  top: 8px;
}

.faq-side-details .acc_icon_expand:before {
  content: '+';
  color: #646464;
  position: absolute;
  left: 1px;
  right: 0;
  text-align: center;
  width: 18px;
  height: 18px;
  top: 0;
}

/* header */
.header-fixed {
  position: fixed !important;
  top: 0px;
  width: 100%;
  z-index: 999;
  animation: animatetop 0.3s;
}

/* checkbox */
.check-field {
  z-index: 1;
  position: absolute;
  right: 0px;
  top: 0px;
}

.check-field label {
  opacity: 0;
}

.check-field [type='checkbox'] {
  opacity: 0;
  position: absolute;
  right: 25px;
  top: 10px;
  width: 28px;
  height: 28px;
  z-index: 1;
  cursor: pointer;
}

.check-field .checkbox {
  cursor: pointer;
  text-align: center;
  position: absolute;
  border-radius: 3px;
  right: 25px;
  width: 28px;
  height: 28px;
  left: unset;
  top: 10px;
  border: 1px solid #acacac;
  background: #fff;
}

.check-field [type='checkbox']:checked + .checkbox {
  background: #e27a34;
  border: 0;
}

.check-field [type='checkbox']:checked + .checkbox:before {
  content: '';
  display: block;
  width: 8px;
  height: 14px;
  right: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 5px;
}

.checkbox-disabled [type='checkbox']:disabled + .checkbox {
  background: #aeaeae;
}

/* checkbox */
.similar-vis-slider .swiper-button-next,
.similar-vis-slider .swiper-button-prev {
  transform: translate(0, -45%) !important;
  top: 45% !important;
}

.custmreview-str .fa {
  color: #e88616;
  margin-right: 2px;
}

.custmreview-str .disable {
  color: #b1b1b1 !important;
}

.p-0 {
  padding: 0px !important;
}

@media (max-width: 1600px) {
  .px-1600-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media (max-width: 991px) {
  .product-add-card-slider .swiper-thumbs .swiper-slide {
    width: 60px !important;
    height: 45px !important;
  }

  .px-md-0 {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (max-width: 767px) {
  .mx-sm-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .container-fluid {
    padding: 0 10px;
    max-width: 100%;
    width: 100%;
  }

  .main-product-section {
    /* padding-top: 60px; */
    background: #f2f2f2;
  }

  .product-right-slider .swiper-button-prev,
  .product-right-slider .swiper-button-next {
    display: none;
  }

  .product-add-card-slider .swiper-thumbs .swiper-slide img {
    display: none;
  }

  .product-add-card-slider .swiper-thumbs .swiper-slide {
    width: 16px !important;
    height: 16px !important;
    cursor: pointer;
    position: relative;
    border: 0px;
    margin: 0px !important;
    font-size: 0px;
    background: transparent;
  }

  .product-add-card-slider .swiper-thumbs .swiper-slide:after {
    width: 8px !important;
    height: 8px;
    border-radius: 50%;
    background-color: silver;
    opacity: 0.25;
    transition: opacity 0.15s ease-in-out;
    border: 0px;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .product-add-card-slider
    .swiper-thumbs
    .swiper-slide.swiper-slide-thumb-active:after {
    background: #e27a34 !important;
    opacity: 1;
  }

  .product-add-card-slider .swiper-watch-progress .swiper-wrapper {
    justify-content: center;
  }

  .youtube-thumbnail:after {
    width: 12px;
    height: 10px;
    content: '';
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translate(0, -50%);
    right: 0px;
    margin: auto;
    border-radius: 2px;
    background-color: silver;
  }

  .youtube-thumbnail:before {
    content: '';
    border: 0;
    background: 0 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 0;
    height: 3px;
    border-color: transparent transparent transparent #fff;
    cursor: pointer;
    border-style: solid;
    border-width: 3px 0 3px 5px;
    border-radius: 0;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50%;
    margin: auto;
    transform: translate(0, -50%);
    z-index: 1;
  }

  .product-add-card-slider
    .swiper-thumbs
    .swiper-slide.swiper-slide-thumb-active
    .youtube-thumbnail:after {
    background: #e27a34 !important;
  }

  .faq-side-details .acc_head {
    font-size: 0.8125rem;
    line-height: 18px;
    font-family: 'robotoregular';
    padding: 10px 10px 10px 35px;
  }

  .faq-side-details .acc_icon_expand:before {
    font-size: 15px;
    color: #000;
    top: -2px;
    right: 0px;
    left: -1px;
  }

  .faq-side-details .acc_icon_expand {
    left: 10px;
    top: 12px;
    width: 18px;
    height: 18px;
  }

  .faq-side-details .accordion_in {
    border: 1px solid #e5e5e5;
    border-bottom: none;
  }

  .faq-side-details .acc_content {
    padding-left: 35px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  .faq-side-details .acc_content p {
    font-size: 13px;
    line-height: 17px;
    color: #515151;
  }

  .similar-vis-slider .swiper-button-next,
  .similar-vis-slider .swiper-button-prev {
    display: none;
  }

  .px-sm-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .dis-loving-slider .swiper-button-prev,
  .dis-loving-slider .swiper-button-next {
    display: none;
  }
}

/* sidebar-filter-right */
.sidebar-filter-right .szh-accordion__item-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
  line-height: normal;
  letter-spacing: 0.01em;
  font-family: 'pangrammedium';
  color: #333333;
  padding: 20px 0 20px 15px;
  border-bottom: 1px solid #e7e7e7;
  width: 100%;
}

.sidebar-filter-right .szh-accordion__item-heading button img {
  opacity: 1;
}

/* radio Sort By */
.sidebar-filter-right .filter-label {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.sidebar-filter-right .filter-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.sidebar-filter-right .filter-label .checkmarkbtn {
  padding-left: 28px;
  font-size: 0.875rem;
  line-height: 18px;
  color: #000;
  display: inline-block;
  overflow: hidden;
  font-family: 'pangramregular';
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidebar-filter-right .filter-label .checkmarkbtn:after {
  content: '';
  position: absolute;
  display: none;
  top: 3px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #e57200;
}

.sidebar-filter-right .filter-label .checkmarkbtn:before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0px;
  height: 20px;
  width: 20px;
  border: 1px solid #00000030;
  border-radius: 50%;
}

.sidebar-filter-right .filter-label input:checked ~ .checkmarkbtn:after {
  display: block;
}

.sidebar-filter-right .filter-label input:checked ~ .checkmarkbtn:before {
  border-color: #e57200;
}

/* redio Sort By */
/* checkbox price-range */
.price-range-list {
  cursor: pointer;
  display: block;
  position: relative;
  margin-bottom: 20px;
}

.price-range-list [type='checkbox'] {
  cursor: pointer;
  display: none;
}

.price-range-list span.dyn_Under-19999 {
  font-size: 0.875rem;
  padding-left: 30px;
  line-height: 20px;
  color: #333333;
  display: inline-block;
  font-family: 'pangramregular';
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 21px;
  background: url('/images/category-new/selected-icon.svg') no-repeat left 0px;
  background-size: 20px;
}

.price-range-list [type='checkbox']:checked + span {
  background-position: 0 -20px;
}

.price-range-list .dyn_Under-19999 .pcounts {
  color: #707070;
  font-size: 12px;
  margin-left: 5px;
  line-height: 18px;
}

.price-range-field {
  margin: 10px 0px 20px;
  display: inline-flex;
}

.field--not-empty {
  position: relative;
}

.field--not-empty.min {
  margin-right: 5px;
}

.field--not-empty .field__label {
  color: #4b4a4a;
  font-size: 13px;
  line-height: 1;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  padding: 0 5px;
  text-overflow: ellipsis;
  top: -5px;
  left: 10px;
  display: inline-block;
  text-align: left;
  transition: all 0.2s ease-out;
  user-select: none;
  white-space: nowrap;
  background: #fff;
  z-index: 1;
  transform: none;
  opacity: 1;
  text-transform: uppercase;
  font-family: 'pangramregular';
}

.field--not-empty .field__input {
  background-color: #fff;
  border-radius: 2px;
  transition: all 0.2s ease-out;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  line-height: 1.15;
  color: #333333;
  font-size: 15px;
  font-family: 'robotoregular';
  border: 1px solid #c3c3c3;
}

.field--not-empty.active .field__input {
  border: 1px solid rgb(255, 0, 0);
}

.filter-footer {
  padding: 15px 20px;
  background: #fff;
  position: fixed;
  bottom: 0;
  width: 24%;
  left: 0px;
  right: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 4px 0px 3px rgba(0, 0, 0, 0.15);
}

.filter-footer .filterbtn {
  padding: 13px 9px;
  color: #333333;
  display: inline-block;
  width: 40%;
  margin: 0 8px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  background: #e7e7e7;
  border-radius: 4px;
  font-family: 'pangrammedium';
  cursor: pointer;
}

.filter-footer .filterbtn.applybtn {
  color: #fff;
  background: linear-gradient(45deg, #ee7120, #ea5039);
}

.toast {
  border-radius: 4px;
}

/* color-filter-list */

.color-filter-list {
  cursor: pointer;
  display: block;
  position: relative;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin-bottom: 25px;
}

.color-filter-list [type='checkbox'] {
  cursor: pointer;
  display: none;
}

.color-filter-list span {
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  display: inline-block;
  font-family: 'pangramregular';
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  background-size: 20px;
}

.color-filter-list [type='checkbox']:checked + span {
  background-position: 0 -20px;
}

.color-filter-list [type='checkbox']:checked + span img {
  padding: 3px;
  border: 1px solid #000;
}

.color-filter-list span .color-img {
  position: relative;
  display: inline-block;
}

.color-filter-list span .color-img:after {
  content: '';
  position: absolute;
  right: 0px;
  margin: auto;
  background: url(/images/category/icn-check-new.svg) no-repeat left 0px;
  height: 13px;
  width: 12px;
  left: 0px;
  top: 13px;
  display: none;
}

.color-filter-list [type='checkbox']:checked + span .color-img:after {
  display: block;
}

.color-filter-list span img {
  border-radius: 50%;
}

.color-list {
  display: inline-block;
  width: 25%;
  padding: 0px 3px;
}

.color-filter-name {
  font-size: 12px;
  color: #000;
  margin-top: 4px;
  white-space: normal;
}

.color-filter-list .pcounts {
  color: #707070;
  font-size: 11px;
  margin-left: 5px;
  line-height: 18px;
}

/* color filter */
.price-range-field-mobile {
  padding: 20px 10px 15px;
  display: inline-flex;
  margin: 0px;
}

/* radio */

.filter-label-radio {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.filter-label-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.filter-label-radio .checkmarkbtn {
  padding-left: 28px;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filter-label-radio .checkmarkbtn:after {
  content: '';
  position: absolute;
  display: none;
  top: 3px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #e57200;
}

.filter-label-radio .checkmarkbtn:before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0px;
  height: 20px;
  width: 20px;
  border: 1px solid #00000030;
  border-radius: 50%;
}

.filter-label-radio input:checked ~ .checkmarkbtn:after {
  display: block;
}

.filter-label-radio input:checked ~ .checkmarkbtn:before {
  border-color: #e57200;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

/* redio  */

.wdRow-cols-3 .ws-skeleton {
  height: 497px;
}

/* .wdRow-cols-3 .product-image-container img {
  height: 497px !important;
} */

/* four */
.wdRow-cols-4 .ws-skeleton {
  height: 368px;
}

.wdRow-cols-4 .product-image-container img {
  height: 368px;
}

/* five */
.wdRow-cols-5 .ws-skeleton {
  height: 290px;
}

.wdRow-cols-5 .product-image-container img {
  height: 290px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media (max-width: 1600px) {
  .wdRow-cols-3 .product-image-container img {
    height: 434px;
  }

  .wdRow-cols-4 .product-image-container img {
    height: 300px;
  }

  .wdRow-cols-5 .product-image-container img {
    height: 238px;
  }
}

@media (max-width: 1370px) {
  .wdRow-cols-3 .product-image-container img {
    height: 351px;
  }

  .wdRow-cols-4 .product-image-container img {
    height: 253px;
  }

  .wdRow-cols-5 .product-image-container img {
    height: 208px;
  }
}

@media (max-width: 1160px) {
  .wdRow-cols-3 .product-image-container img {
    height: 295px;
  }

  .wdRow-cols-4 .product-image-container img {
    height: 218px;
  }

  .wdRow-cols-5 .product-image-container img {
    height: 172px;
  }
}

@media (max-width: 1024px) {
  .wdRow-cols-3 .product-image-container img {
    height: 260px;
  }

  .wdRow-cols-4 .product-image-container img {
    height: 190px;
  }

  .wdRow-cols-5 .product-image-container img {
    height: 157px;
  }
}

.color-range-list span {
  overflow: inherit;
  padding-left: 27px;
}

.color-range-list span img {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  vertical-align: text-top;
  margin-right: 5px;
  border: 1px solid #e5e5e5;
}

/* review-slider-modal */
.review-slider-modal .swiper-button-next,
.review-slider-modal .swiper-button-prev {
  z-index: 99;
  background: rgba(255, 255, 255, 0.7);
  width: 27px;
  height: 27px;
  border-radius: 100%;
  border: 1px solid #717171;
  transform: translate(0, -54%);
  top: 54% !important;
}

.review-slider-modal .swiper-button-next:after,
.review-slider-modal .swiper-button-prev:after {
  background-color: transparent;
  width: 14px;
  height: 14px;
  min-width: 14px;
  background-size: contain;
  background-position: center;
}

.review-slider-modal .swiper-button-prev:after {
  background-image: url(/images/category/modal-left-arrow.svg);
}

.review-slider-modal .swiper-button-next:after {
  background-image: url(/images/category/modal-right-arrow.svg);
}

.review-slider-modal .swiper-button-next {
  /* left: 44%; */
  margin-right: 8px;
  transform: translate(2px, -54%);
}

.review-slider-modal .swiper-button-prev {
  left: 6px !important;
}

/* cart-guest */
.check-field-left {
  position: relative;
  padding-left: 28px;
}

.check-field-left label {
  opacity: 1;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #515151;
}

.check-field-left .checkbox {
  left: 0px;
  width: 20px;
  height: 20px;
  top: 0px;
  border-radius: 3px;
}

.check-field-left [type='checkbox'] {
  left: 0px;
  width: 20px;
  height: 20px;
  top: 0px;
}

.check-field-left [type='checkbox']:checked + .checkbox:before {
  width: 7px;
  height: 11px;
  right: 7px;
  border-width: 0 2px 2px 0;
  top: 3px;
}

/* cart-guest */
.radio-md {
  margin-bottom: 0px;
}

.radio-md .checkmarkbtn {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #515151;
  letter-spacing: 0.5px;
  font-family: 'pangramregular';
  padding-left: 23px;
}

.radio-md .checkmarkbtn:before {
  height: 18px;
  width: 18px;
}

.radio-md .checkmarkbtn:after {
  width: 10px;
  height: 10px;
  background: #e27a34;
}

.radio-disabled input:disabled {
  opacity: 0;
}

.radio-disabled input:disabled ~ .checkmarkbtn:after {
  background: #aeaeae;
}

.radio-disabled input:disabled ~ .checkmarkbtn:before {
  border-color: #aeaeae;
}

/* select */
.select-box {
  position: relative;
  z-index: 2;
}

.w-100 {
  width: 100%;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

/* select */
input:disabled,
input[readonly] {
  background-color: #f5f5f5;
  border: 1px solid #d1d1d1;
  opacity: 1;
}

/* happy-cust-slider */
.happy-cust-slider .swiper-button-prev {
  left: 8px !important;
  top: 58%;
  transform: translate(0, -58%);
}

.happy-cust-slider .swiper-button-next {
  right: 8px !important;
  top: 58%;
  transform: translate(0, -58%);
}

.happy-cust-slider .swiper-button-prev:after,
.happy-cust-slider .swiper-button-next:after {
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #fff;
  background: linear-gradient(
    136deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 99%
  );
  background-size: 17px;
  background-position: center;
  background-repeat: no-repeat;
  backdrop-filter: blur(40px);
}

.happy-cust-slider .swiper-button-prev:after {
  background-image: url('/images/customer/cust-slider-left-arrow.svg');
}

.happy-cust-slider .swiper-button-next:after {
  background-image: url('/images/customer/cust-slider-right-arrow.svg');
}

.cust-select {
  background-color: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  margin-bottom: 10px;
}

.cust-select h3 {
  font-size: 12px;
  line-height: 16px;
  color: #e27a34;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-family: 'pangramregular';
  margin: 0px 0px 1px;
  padding: 7px 10px 0px;
}

.cust-select .dropdown-header {
  border: 0px;
  height: auto;
  font-size: 14px;
  line-height: 20px;
  color: #646464;
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-family: 'pangramregular';
  padding: 2px 10px 7px;
}

.cust-select .dropdown-list {
  margin-top: 1px;
}

.cust-select .select-arrow {
  top: 29%;
  transform: translate(0, -30%);
}

.cust-select .select-arrow:after {
  background-image: url('/images/customer/cust-select-arrow.svg');
  width: 15px;
  height: 8px;
  background-size: 15px;
}

.customer-video {
  height: 492px;
  width: 100%;
  object-fit: cover;
  border: 0px;
  display: block;
}

.see-store-video {
  height: 450px;
}

.collectionsslider .swiper-button-prev,
.collectionsslider .swiper-button-next {
  transform: translate(0, -48%);
  top: 48% !important;
}

.overflow-add {
  overflow: hidden;
}

.terms-slide .head {
  font-size: 1rem;
  line-height: 24px;
  font-family: robotomedium;
  display: block;
  color: #333;
}

.terms-slide ol {
  margin: 14px 0px;
}

.terms-slide ol li {
  margin: 0 0 5px;
  color: #646464;
  font-size: 0.8125rem;
  line-height: 18px;
}

.terms-slide table thead th {
  padding: 5px;
  color: #646464;
  font-size: 0.8125rem;
  line-height: 18px;
  font-family: 'robotoregular';
}

.terms-slide table tbody td {
  padding: 5px;
  color: #646464;
  font-size: 0.8125rem;
  line-height: 18px;
  font-family: 'robotoregular';
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  margin-bottom: 15px;
}

.field-input {
  border: 1px solid #d9d9d9;
  color: #000;
  background-color: #fff;
  border-radius: 2px;
  font-size: 0.8125rem;
  transition: all 0.2s ease-out;
  width: 100%;
  height: 40px;
  padding: 8px 10px;
}

.field-input::placeholder {
  color: #596470;
}

.field-inpu:focus {
  border: 1px solid #000;
}

.submit-btn {
  background-color: #e57200;
  border-radius: 3px;
  font-size: 1.125rem;
  font-family: 'robotomedium';
  font-weight: 400;
  text-transform: uppercase;
  padding: 13px;
  width: 100%;
  margin: 5px 0px 0px;
}

.h-70 {
  height: 70px;
}

.text-decoration-none {
  text-decoration: none !important;
}

.border-bottom-0 {
  border-bottom: 0px !important;
}

/* font-weight */
.font-weight-500 {
  font-weight: 500 !important;
}

.space-x-6 .wdgx-3 {
  --bs-gutter-x: 12px;
}

.view-more-btn-sm {
  background-color: transparent;
  display: block;
  padding: 8px 20px;
  color: #646464;
  border: 1px solid #646464;
  border-radius: 4px;
  text-align: center;
  font-family: 'pangramregular';
  letter-spacing: 0.01em;
  display: inline-flex;
  align-items: center;
}

.overflow-hidden {
  overflow: hidden;
}

/* location */
.locations-breadcrumb {
  padding: 0px 45px;
}

.location-city {
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
  max-width: 1240px;
}

.location-city .heading {
  font-size: 1.75rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 5px;
  font-family: 'robotoregular';
}

.location-city .city-coloum {
  color: #333;
  font-size: 0.9375rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #e5e5e5;
}

.location-city .city-coloum:last-child {
  border-bottom: none;
}

.location-city .state {
  width: 15%;
  color: #e57200;
}

.location-city .city-name {
  width: 84%;
}

.location-city a {
  color: #333;
}

.itemslider .swiper-button-next,
.itemslider .swiper-button-prev {
  transform: translate(0, -45%) !important;
  top: 45% !important;
}

/* ----------------slider */
.fancybox__footer {
  display: none !important;
}

.is-compact .fancybox__footer {
  background-color: transparent !important;
}

.fancybox__container .fancybox__backdrop {
  background-color: #fff;
}

.fancybox__container .f-thumbs__slide__button {
  border-radius: 0px !important;
}

.fancybox__container .f-thumbs__slide__img {
  border-radius: 0px !important;
}

.fancybox__container .is-modern .f-thumbs__slide {
  width: 96px !important;
  transform: translate3d(0px, 0px, 0px) !important;
  left: 0px !important;
  height: 67px;
  --shift: none !important;
  --progress: none !important;
}

.fancybox__container .f-thumbs__slide__button {
  width: 100% !important;
  clip-path: none !important;
}

.fancybox__container .is-modern .f-thumbs__track {
  transform: none !important;
  overflow: auto !important;
  white-space: nowrap !important;
}

.fancybox__container .is-modern .f-thumbs__track::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  display: none;
}

.fancybox__container .is-modern .f-thumbs__track::-webkit-scrollbar-track {
  background: transparent;
}

.fancybox__container .is-modern .f-thumbs__track::-webkit-scrollbar-thumb {
  background: transparent;
}

.fancybox__container .f-button svg {
  filter: brightness(0.3) !important;
}

.fancybox__container .fancybox__toolbar__column .f-button {
  background: transparent;
}

/* ----------------slider */
.find-store-btn {
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  padding: 4px 27px;
  border-radius: 100px;
}

.field__select {
  margin-bottom: 0px;
  border: 0px;
}

.field__select .dropdown-header {
  padding: 13px 10px;
  border-radius: 3px;
  letter-spacing: 0.5px;
  border: 1px solid #e7e7e7;
}

.field__select .select-arrow:after {
  right: 5px;
  top: 5px;
}

/* grievanc-section */

.grievanc-rdsl-section {
  background-color: #f6f6f6;
  padding: 25px 0px 30px;
}

.grievanc-rdsl-section .write-to-us {
  padding: 53px 40px 50px;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  max-width: 1100px;
}

.grievanc-rdsl-section h1 {
  font-size: 2.1875rem;
  font-family: 'robotolight';
  color: #525252;
  font-weight: normal;
  line-height: normal;
  margin: 0px 0px 45px;
}

.grievanc-rdsl-section p {
  color: #000;
  font-size: 0.875rem;
  font-family: 'robotolight';
  margin: 0px 0px 25px;
  line-height: 28px;
  font-weight: normal;
}

.grievanc-rdsl-section .contact-info p {
  margin: 0px 0px 0px;
}

.grievanc-rdsl-section .contact-info p span {
  padding-right: 10px;
}

.grievanc-rdsl-section .contact-info p b {
  font-family: 'robotobold';
}

.grievanc-rdsl-section .contact-info .btn-text {
  padding-top: 15px;
}

.write-to-us .click-here-btn {
  background: #f3601d;
  color: #fff !important;
}

.grievanc-rdsl-section .contact-info .click-here-btn {
  background: #e57200;
  color: #fff;
  font-size: 0.875rem;
  font-family: 'robotoregular';
  padding: 3px 10px;
  border-radius: 3px;
  display: inline-block;
  margin-top: 5px;
  line-height: normal;
}

/* grievanc-section */

.categ-cust-slider .swiper-button-prev:after {
  width: 30px;
  min-width: 30px;
  height: 32px;
  background-size: 30px;
}

.categ-cust-slider .swiper-button-next:after {
  width: 30px;
  min-width: 30px;
  height: 32px;
  background-size: 30px;
}

/* .premium-card.active{
    border: 2px solid #ff8e19 !important;
} */
.emi-plan-options-modal {
  display: block;
  margin-top: -1px;
}

.emi-plan-options-modal
  .szh-accordion__item
  .szh-accordion__item-heading
  button {
  width: 100%;
  text-align: left;
  padding: 0px;
  padding: 12px 15px;
  font-size: 14px;
  line-height: 1.15;
  border-top: 1px solid #e5e5e5;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu,
    Arial, 'Helvetica Neue', sans-serif;
}

.emi-plan-options-modal
  .szh-accordion__item
  .szh-accordion__item-heading
  button
  img {
  width: 13px;
  transform: rotate(-90deg);
  filter: brightness(0.1);
  float: right;
}

.emi-plan-options-modal
  .szh-accordion__item.szh-accordion__item--expanded
  .szh-accordion__item-heading
  button {
  color: #e27a34;
  background: #f0f2f4;
  border-bottom: 1px solid #e5e5e5;
}

.emi-plan-options-modal
  .szh-accordion__item.szh-accordion__item--expanded
  .szh-accordion__item-heading
  button
  img {
  transform: rotate(0deg);
}

.emi-plan-options-modal .szh-accordion__item table thead tr {
  border-bottom: 0px;
}

.emi-plan-options-modal
  .szh-accordion__item:nth-last-child(1)
  .szh-accordion__item-heading
  button {
  border-bottom: 1px solid #e5e5e5;
}

.emi-plan-options-modal .szh-accordion__item .szh-accordion__item-content {
  padding-bottom: 10px;
}

.emi-plan-options-modal
  .szh-accordion__item:nth-last-child(1)
  .szh-accordion__item-content {
  border-bottom: 1px solid #e5e5e5;
}

.emi-plan-options-modal .szh-accordion__item table thead {
  background-color: #fff;
}

.emi-plan-options-modal .szh-accordion__item table thead th {
  padding: 15px 15px 7px;
  font-size: 0.8125rem;
  font-family: robotomedium, Arial, Helvetica, sans-serif;
  line-height: 1.15;
  text-align: left;
}

.emi-plan-options-modal .szh-accordion__item table {
  background-color: transparent;
}

.emi-plan-options-modal .szh-accordion__item table tr {
  background-color: transparent;
  border: 0px;
}

.emi-plan-options-modal .szh-accordion__item table tbody td {
  border: none;
  font-size: 0.75rem;
  padding: 0px 0px 5px 15px;
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu,
    Arial, 'Helvetica Neue', sans-serif;
  line-height: 1.15;
}

.leaflet-top.leaflet-left {
  left: auto;
  right: 7px;
  bottom: 23px;
  top: auto;
}

.leaflet-bar a {
  color: #646464 !important;
}

.leaflet-touch .leaflet-bar {
  box-shadow: 0px 1px 2px 0px #0000001a !important;
  border: 0px !important;
}

.leaflet-container .leaflet-control-attribution {
  background: rgba(255, 255, 255, 0.3) I !important;
}

.leaflet-touch .leaflet-bar a {
  width: 32px !important;
  height: 32px !important;
  border-bottom: 0px;
  font-size: 24px;
}

.leaflet-touch .leaflet-bar a span {
  font-family: 'Roboto';
  font-size: 28px;
}

.leaflet-touch .leaflet-bar a.leaflet-control-zoom-in span {
  font-size: 23px;
}

.leaflet-touch .leaflet-bar a.leaflet-control-zoom-in {
  position: relative;
  overflow: hidden;
}

.leaflet-touch .leaflet-bar a.leaflet-control-zoom-in:after {
  content: '';
  position: absolute;
  right: 0px;
  left: 0px;
  bottom: 0px;
  margin: auto;
  width: 25px;
  height: 2px;
  background-color: #f5f5f5;
}

.leaflet-control-attribution a {
  color: #333333 !important;
}

.expe-stores-map-h .leaflet-container {
  height: 687px !important;
}

.furniture-store-slider .swiper-button-prev:after,
.furniture-store-slider .swiper-button-next:after {
  /* display: none; */
  width: 25px;
  height: 30px;
}

.furniture-store-slider .swiper-thumbs .swiper-slide {
  width: 103px;
}

.furniture-store-slider .swiper-thumbs .swiper-wrapper {
  flex-wrap: nowrap;
}

.furniture-store-slider .swiper-horizontal .swiper-slide img {
  width: 100%;
  height: 100%;
}

.furniture-store-slider .mySwiper2 .swiper-slide {
  height: auto;
}

/*     */
.product-add-card-slider.product-page-slider .swiper-thumbs {
  margin-top: 0px !important;
}

.product-add-card-slider.product-page-slider .swiper-slide {
  height: auto;
}

/* furnitu-fran-faq */
.furnitu-fran-faq
  .szh-accordion
  .accrodion-inner:nth-last-child(1)
  .szh-accordion__item {
  border-bottom: 0px;
}

.furnitu-fran-faq .szh-accordion__item {
  border-bottom: 1px solid #e7e7e7;
  padding: 8px 0px;
}

.furnitu-fran-faq .szh-accordion__item .szh-accordion__item-btn {
  position: relative;
  padding: 12px 10px;
  text-align: left;
}

.furnitu-fran-faq .szh-accordion__item .szh-accordion__item-btn h4 {
  margin: 0px;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.3px;
  color: #333333;
  font-weight: 500;
  font-family: 'pangrammedium';
}

.furnitu-fran-faq .szh-accordion__item .szh-accordion__item-btn:after {
  background-image: url('/images/Plus.svg');
  content: '';
  position: absolute;
  right: 11px;
  top: 8px;
  z-index: 1;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
}

.furnitu-fran-faq
  .szh-accordion__item.szh-accordion__item--status-entered
  .szh-accordion__item-btn:after {
  background-image: url('/images/Minus.svg');
}

.furnitu-fran-faq
  .szh-accordion__item.szh-accordion__item--status-entered
  .szh-accordion__item-btn {
  background-color: #f5f5f5;
}

/* furnitu-fran-faq */
/* sell-on-faq */
.sell-on-faq .szh-accordion__item {
  border: 1px solid #eeecec;
}

/* sell-on-faq */
.wdRow-cols-3 .categ-card-grid {
  padding-left: 0.6%;
  padding-right: 0.6%;
}

.wdRow-cols-4 .categ-card-grid {
  padding-left: 0.4%;
  padding-right: 0.4%;
}

.wdRow-cols-5 .categ-card-grid {
  padding-left: 0.6%;
  padding-right: 0.6%;
}

.our-experience-slider .swiper-button-prev,
.our-experience-slider .swiper-button-next {
  transform: translate(0, -57%);
  top: 57%;
}

.view-all-media-btn {
  padding: 10px 20px;
  border: 1px solid var(--bg-orange-btn);
  color: var(--bg-orange-btn);
  border-radius: 4px;
  font-weight: 400;
  display: inline-block;
  transition: 0.3s all ease-in-out;
  font-family: 'pangramregular';
  font-size: 14px;
  line-height: 20px;
}

.view-all-media-btn:hover {
  background: var(--bg-orange-btn-hover);
  color: #fff;
  transition: 0.3s all ease-in-out;
}

.swiper-disabled-none .swiper-button-prev.swiper-button-disabled,
.swiper-disabled-none .swiper-button-next.swiper-button-disabled {
  opacity: 0;
}

.happywordslider .swiper-button-next,
.happywordslider .swiper-button-prev {
  transform: translate(0, -57%);
  top: 57% !important;
}

.Toastify .Toastify__toast-body a {
  color: #fff;
}

@media (max-width: 1280px) {
  .header-fixed {
    border-bottom: 1px solid #e5e5e5 !important;
    left: 0px;
    right: 0px;
    padding: 8px 40px 0px;
  }
}

@media (max-width: 1200px) {
  .quick-view-product-slider .swiper .swiper-slide img {
    height: auto;
  }
}

@media (max-width: 1200px) {
  .quick-view-product-slider .swiper .swiper-slide img {
    height: auto;
  }

  .locations-breadcrumb {
    padding: 0px 0px;
  }
}

@media (max-width: 991px) {
  .customer-video {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .common-wrapper {
    overflow: hidden;
  }

  .happy-cust-slider .swiper-button-next {
    display: none;
  }

  .happy-cust-slider .swiper-button-prev {
    display: none;
  }

  .mobile-wrapper {
    /* padding-top: 61px; */
  }

  .customer-video {
    height: 350px;
  }

  .pt-sm-0 {
    padding-top: 0px !important;
  }

  .view-more-white-btn {
    padding: 8px 20px;
    font-size: 14px;
    line-height: 18px;
    background: #fff;
    color: #515151;
    border: 1px solid #8b8b8b;
    border-radius: 4px;
    font-family: 'pangrammedium';
  }

  .hot-seller-slider .swiper-button-next,
  .hot-seller-slider .swiper-button-prev {
    display: none;
  }

  .heading {
    font-size: 1.5rem;
    margin: 0;
  }

  .city-coloum {
    display: block;
    font-size: 0.875rem;
    padding: 10px;
  }

  .state {
    width: 100%;
  }

  .city-name {
    width: 100%;
    padding-top: 3px;
  }

  /* .locations-wrapper {padding-top: 60px;} */

  .location-city .heading {
    font-size: 1.5rem;
    font-family: 'pangrammedium';
    font-weight: 500;
  }

  .location-city {
    background: #fff;
    padding: 20px 0px;
  }

  .location-city .city-coloum {
    display: block;
    font-size: 0.875rem;
    padding: 10px;
    font-family: 'robotoregular';
    line-height: normal;
  }

  .cus-story-wrapper {
    overflow: hidden;
    /* padding-top: 60px; */
  }

  .itemslider .swiper-button-next,
  .itemslider .swiper-button-prev {
    display: none;
  }

  .fancybox__footer {
    display: block !important;
  }

  .sitemap-wrapper {
    overflow: hidden;
    /* padding-top: 60px; */
  }

  .pay-policy-wrapper {
    overflow: hidden;
    /* padding-top: 60px; */
  }

  .grievanc-rdsl-section {
    background-color: #fff;
    padding: 25px 8px 35px;
  }

  .grievanc-rdsl-section .write-to-us {
    padding: 0px;
    border: 0px;
    max-width: 100%;
  }

  .grievanc-rdsl-section h1 {
    margin: 0px 0px 30px;
    font-size: 1.875rem;
    color: #525252;
    line-height: 30px;
    font-weight: 500;
    font-family: 'robotomedium';
  }

  .grievanc-rdsl-section h1 .heading {
    font-size: 1.875rem;
    font-size: 38px;
  }

  .grievanc-rdsl-section p {
    font-size: 0.875rem;
    line-height: 22px;
    font-family: 'robotoregular';
    margin: 0px 0px 16px;
  }

  .grievanc-rdsl-section .contact-info p {
    margin: 0px 0px 16px;
  }

  .grievanc-rdsl-section .contact-info .btn-text {
    padding-top: 7px;
    margin: 0px 0px 8px;
    font-family: 'robotomedium';
  }

  .grievanc-rdsl-section .contact-info .click-here-btn {
    background: #f3601d;
    padding: 5px 20px;
    line-height: 1.15;
  }

  .btn-find-store {
    padding: 8px 25px;
    font-size: 15px;
    line-height: 20px;
  }

  .similar-modal-slider .swiper-button-prev {
    display: none;
  }

  .similar-modal-slider .swiper-button-next {
    display: none;
  }

  .other-store-slider .swiper-button-next,
  .other-store-slider .swiper-button-prev {
    display: none;
  }

  .slider-arrow-outside .swiper {
    padding: 0px 0px;
  }

  .slider-arrow-outside .swiper:after {
    content: none;
  }

  .slider-arrow-outside .swiper:before {
    content: none;
  }

  .furniture-store-slider .swiper-thumbs .swiper-wrapper {
    margin-top: 10px;
  }

  .furniture-store-slider .swiper-thumbs .swiper-slide:after {
    width: 8px !important;
    height: 8px;
    background-color: #c4c4c4;
    opacity: 1;
  }

  .furniture-store-slider .swiper-thumbs .swiper-slide {
    margin: 0px 3px !important;
    height: 14px !important;
    width: 14px !important;
  }

  .furniture-store-slider .swiper-horizontal .swiper-slide img {
    object-fit: cover;
  }

  .review-summary-slider .swiper-button-next,
  .review-summary-slider .swiper-button-prev {
    display: none;
  }

  .about-top-store-slider .swiper-button-next,
  .about-top-store-slider .swiper-button-prev {
    display: none;
  }

  .terms-slide .head {
    display: none;
  }

  .terms-slide {
    padding: 15px;
  }

  .terms-slide ol {
    padding-left: 20px;
  }

  .terms-slide ol li {
    font-size: 14px;
    line-height: 20px;
  }

  .furnitu-fran-faq .szh-accordion__item .szh-accordion__item-btn h4 {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0px;
    font-weight: 400;
    font-family: 'pangrammedium';
    padding-right: 22px;
  }

  .furnitu-fran-faq .szh-accordion__item {
    padding: 0px 0px;
  }

  .furnitu-fran-faq .szh-accordion__item .szh-accordion__item-btn:after {
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
  }

  .content-common table tbody td {
    border-color: #808080;
    font-size: 12px;
    padding: 5px;
    color: #424242;
  }

  .content-common table thead th {
    border-color: #808080;
    font-size: 12px;
    color: #424242;
  }

  .content-common table {
    margin-bottom: 10px;
  }
}

@media (max-width: 500px) {
  .customer-video {
    height: 258px;
  }
}

@media (max-width: 350px) {
  .furniture-store-slider .swiper-horizontal .swiper-slide {
    height: 240px;
  }
}

@media (max-width: 320px) {
  .location-city .city-coloum {
    font-size: 14px;
  }
}

/* happy-cust-slider */

/* ====== hotel-furniture banner slider arrow css start ====== */

.hotel-furniture-bannerSlider .swiper-button-next::after,
.hotel-furniture-bannerSlider .swiper-button-prev::after {
  background-image: url("data:image/svg+xml,%3Csvg width='25' height='47' viewBox='0 0 25 47' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.25 1.89551L23.125 23.7705L1.25 45.6455' stroke='white' style='stroke:white;stroke-opacity:1;' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-color: transparent;
  width: 21px;
  height: 43px;
  background-size: contain;
  margin-right: 20px;
}

.hotel-furniture-bannerSlider .swiper-button-prev {
  transform: translate(0, -50%) scale(-1);
}

@media (max-width: 991px) {
  .hotel-furniture-bannerSlider .swiper-button-next::after,
  .hotel-furniture-bannerSlider .swiper-button-prev::after {
    height: 30px;
  }
}

@media (max-width: 767px) {
  .hotel-furniture-bannerSlider .swiper-button-next,
  .hotel-furniture-bannerSlider .swiper-button-prev {
    display: none;
  }
}

/* ====== hotel-furniture banner slider arrow css end ====== */

/* ====== door-step slider arrow css start ====== */
.door-slider .swiper-button-next,
.door-slider .swiper-button-prev {
  top: 58%;
}

@media (max-width: 767px) {
  .door-slider .swiper-button-next,
  .door-slider .swiper-button-prev {
    display: none;
  }
}

/* ====== door-step slider arrow css end ====== */
/* ====== customer-review-slider slider arrow css start ====== */
.customer-review-slider .swiper-button-next,
.customer-review-slider .swiper-button-prev {
  top: unset;
  bottom: -15px;
}

.customer-review-slider .swiper-button-next {
  right: 15px !important;
}

.customer-review-slider .swiper-button-prev {
  left: unset !important;
  right: 65px !important;
}

.customer-review-slider .swiper-button-next::after,
.customer-review-slider .swiper-button-prev::after {
  border-radius: 50%;
  background-size: 25px;
  background-position: center;
}

@media (max-width: 767px) {
  .customer-review-slider .swiper-button-next,
  .customer-review-slider .swiper-button-prev {
    bottom: -26px;
  }
}

/* ====== customer-review-slider slider arrow css end ====== */
/* @media (max-width: 767px) {.furniture-export {margin-top: 60px;}} */

/* 09/23/2024 about us changes css start */
@media (max-width: 767px) {
  .store-cities-mobile-slider .swiper-button-prev,
  .store-cities-mobile-slider .swiper-button-next {
    display: none;
  }
  .swiper-wrrow-sm-none .swiper-button-prev,
  .swiper-wrrow-sm-none .swiper-button-next {
    display: none;
  }
}

/* 09/23/2024 about us changes css end */

/* new css */

.about-swiper .swiper-button-prev,
.about-swiper .swiper-button-next {
  top: 58% !important;
}

.story-slider .swiper-button-prev,
.story-slider .swiper-button-next {
  -webkit-text-fill-color: black;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #e7e7e7;
  top: 55% !important;
}

.ml15 {
  margin-left: 10px !important;
}

.referafriend .swiper-pagination {
  position: unset !important;
}

.referafriend .swiper-pagination-bullet-active {
  background-color: #e57200 !important;
}

main {
  min-height: 100vh;
}

main.track-order-page {
  min-height: auto !important;
}

.swiper-thumb-wrapper .swiper-wrapper {
  transform: none !important;
}

.mx-650 {
  width: 650px;
  margin: 0 auto;
}

#most-viewed {
  display: none !important;
}

.style_home-wrapper__QXfM1
  .style_sofasetsection__J_6Yn
  .style_sofasetheading__yiaAY
  h1 {
  font-family: 'Abhay-Libre Bold';
  font-size: 38px;
  line-height: 40px;
}

.style_home-wrapper__QXfM1
  .style_exploresofasection__YJhco
  .style_exploresofaheading__2k4aM
  .style_subtitle__OWBQX {
  font-family: 'Abhay-Libre Bold';
  font-size: 38px;
  line-height: 40px;
}
.style_home-wrapper__QXfM1
  .style_shopsofasizesection__eoxin
  .style_shopsofasizeheading__g0kjP
  .style_title__kMpYO,
.style_home-wrapper__QXfM1
  .style_populrsofasection__UrOn7
  .style_populrsofaheading__eGHz5
  .style_title__2Ukbr {
  font-family: 'Abhay-Libre Bold';
  font-size: 38px;
  line-height: 40px;
}
